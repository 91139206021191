/* eslint-disable react/jsx-no-target-blank */
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../Components/Footer";
import {
  downloadAfricaImg,
  DownloadBack,
  newibeorlogo,
  iphonePoster,
} from "../Utils/Images";
import { DownloadBtnIcon } from "../Utils/SvgImagePath";
import { DownloadBtnMobile } from "./Download";

export default function DownloadAfrica() {
  const navigate = useNavigate();
  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <DownloadWrapper>
        <DownloadContainer height='100vh'>
          <LeftSection>
            <div className="upPart">
              <img
                src={newibeorlogo}
                alt="iBeorLogo"
                onClick={() => navigate("/")}
              />

              <h1 className="desktopText">
                Find Singles near <br />
                you Today
              </h1>
              <h1 className="mobileText">
                #1 Dating App for
                <br />
                Black Singles
              </h1>
              <DownloadBtnMobile>
                <a target="_blank" href="https://blacks.ibeor.com/app">
                  Download iBeor<sup>®</sup>{" "}
                </a>
              </DownloadBtnMobile>
            </div>

            {/* <div className="MobileImage">
              <img src={downloadAfricaImg} alt="Iphone" />
            </div> */}

            {/* <div className="downPart">
            <a href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843">
              <img src={AppleStore} alt="Apple Store" />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US">
              <img src={GooglePlay} alt="Google Play" />
            </a>
          </div> */}

            <DownloadBtnWrapper>
              {/* <a target="_blank" href="https://bit.ly/blacks_app"> */}
              <a target="_blank" href="https://blacks.ibeor.com/app">
                <button>
                  <span>Download</span>
                  <DownloadBtnIcon
                    style={{ width: "65px", height: "40px" }}
                    color={"#8b6755"}
                  />
                </button>
              </a>
            </DownloadBtnWrapper>
          </LeftSection>
          <RightSection>
            <img src={downloadAfricaImg} alt="Iphone" />
          </RightSection>
        </DownloadContainer>
      </DownloadWrapper>
      <SupportedPlatform>
        <DownloadContainer style={{ flexDirection: "column" }}>
          <h1>Supported Platforms and Devices</h1>
          <h4>
            iBeor is currently available on iOS, and Android or get more
            information on ibeor.com
            <br /> You can also visit the{" "}
            <a
              href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843"
              target="_blank"
            >
              {" "}
              Apple Store
            </a>{" "}
            and search ibeor or{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.ibeor"
              target="_blank"
            >
              Google Play store
            </a>{" "}
            .<br /> iBeor currently supports iOS 15.4 and up, Android 7.0 and
            up, and the latest versions of all.
          </h4>

          {/* <DownloadBtnAfrica>
            <span>iBeor Africa</span>
            <DownloadBtnIcon color={"#fff"} />
          </DownloadBtnAfrica> */}
        </DownloadContainer>
      </SupportedPlatform>
      <div className="white-space" />

      {size >= 500 && <Footer />}
    </div>
  );
}

// const DownloadBtnAfrica = styled.div`
//   padding: 3px;
//   border-radius: 50px;
//   border: 0px solid rgb(63, 118, 82);
//   background: #b9584b;
//   width: 220px;
//   height: 42px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   transition: border-radius 0.2s linear 0.1s;
//   margin: 10px 0 140px 0;
//   span {
//     transition: inherit;
//     margin: 0px 4px 0px 0px;
//     color: #ffffff;
//     font-style: normal;
//     letter-spacing: 0.25em;
//     font-family: avenir-lt-w01_35-light1475496, sans-serif;
//     font-size: 18px;
//     font-weight: 700;
//   }

//   svg {
//     width: 40px;
//     height: 20px;
//   }

//   :hover {
//     background: transparent;

//     svg {
//       width: 50px;
//       height: 30px;
//     }
//   }
// `;

const SupportedPlatform = styled.div`
  width: 100%;
  height: 100%;
  background: #3f7652;
  color: #fff;
  padding-bottom: 120px;

  h1 {
    font-size: 56px;
    margin: 35px 0;
    font-family: "Noto Sans", sans-serif;
  }

  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;

    font-family: "Noto Sans", sans-serif;
    padding: 20px 0;
  }

  a {
    color: #fff;
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 40px;
    }

    h4 {
      font-size: 18px;
    }
  }

  @media (max-width: 878px) {
    h1 {
      font-size: 30px;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const RightSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  img {
    width: 410px;
    padding: 10px;
    margin: 60px 0 0 0;
  }

  @media (max-width: 1420px) {
    height: 100%;
    img {
      width: 80%;
      padding: 10px;
      margin: 60px 0 0 0;
    }
  }

  @media (max-width: 873px) {
    img {
      width: 100%;
      padding: 10px;
      margin: 60px 0 0 0;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
const LeftSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;

  .upPart {
    margin: 0 0 20px 0;

    img {
      width: 430px;
      padding: 10px;
      cursor: pointer;
    }

    h1 {
      font-size: 45px;
      margin: 35px 20px;
      font-family: "Noto Sans", sans-serif;
      letter-spacing: 0.01em;
      padding: 0 30px;
    }
    .mobileText {
      display: none;
    }
    @media (max-width: 600px) {
      .desktopText {
        display: none;
      }
      .mobileText {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 9vw;
        padding: 0 10px;
      }
    }
  }

  .downPart {
    img {
      width: 190px;
      margin: 0 20px;
    }
  }

  .MobileImage {
    img {
      display: none;
    }
  }

  @media (max-width: 1420px) {
    .upPart {
      margin: 0;
      img {
        width: 70%;
        padding: 10px;
        cursor: pointer;
      }

      h1 {
        font-size: 35px;
        margin: 10px;
        font-family: "Noto Sans", sans-serif;
        letter-spacing: 0.01em;
        padding: 0 30px;
      }
    }

    .downPart {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: end;

      img {
        width: 40%;
        margin: 0 20px;
      }
    }
  }

  @media (max-width: 873px) {
    .upPart {
      h1 {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 600px) {
    .upPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      img {
        width: 100%;
        padding: 10px;
        cursor: pointer;
      }

      h1 {
        font-size: 18px;
      }
    }

    .downPart {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0;

      img {
        width: 100px;
        margin: 0 20px;
      }
    }

    .MobileImage {
      img {
        display: block;
        width: 40%;
        margin: auto;
      }
    }
  }
`;

const DownloadContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  /* @media (max-width: 500px) {
    width: 80%;
  } */
  @media (max-width: 600px) {
    width: 90%;
    height: ${(props)=>props.height || '100%'};
  }
`;

const DownloadWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${DownloadBack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const DownloadBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 87px;

  button {
    transition: border-radius 0.2s linear 0.1s;
    padding: 10px 60px;
    font-size: 28px;
    color: #8b6755;
    border-radius: 30px;

    :hover {
      border-radius: 15px;
    }
  }
  .icon {
    width: 50px;
    height: 30px;
  }

  @media (max-width: 1330px) {
    button {
      padding: 5px 60px;
    }
  }

  @media (max-width: 1300px) {
    button {
      padding: 5px 30px;
      white-space: nowrap;
    }
  }
  @media (max-width: 940px) {
    button {
      padding: 0px 30px;
      white-space: nowrap;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
  /* @media (max-width: 570px) {
    width: 100%;
    margin: 21px 0px;
  } */
`;
