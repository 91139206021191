import React, { useEffect } from "react";

const PreloadImages = ({ images }) => {
  useEffect(() => {
    const head = document.head || document.getElementsByTagName("head")[0];

    // Dynamically create link elements for preloading each image
    images.forEach((imageUrl) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = imageUrl;
      head.appendChild(link);
    });

    // Clean up function to remove the created link elements on component unmount
    return () => {
      images.forEach((imageUrl) => {
        const link = head.querySelector(`link[href="${imageUrl}"]`);
        if (link) {
          head.removeChild(link);
        }
      });
    };
  }, [images]);

  // Return null since this component doesn't render anything
  return null;
};

export default PreloadImages;
