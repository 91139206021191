export const DownloadBtnIcon = (props) => {
  return (
    <svg
      {...props}
      data-bbox="20 20 160 160"
      viewBox="0 0 200 200"
      height="200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
      data-type="shape"
    >
      <g>
        <path
          d="M100 20c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80zm35.533 91.251l-30.37 28.03a7.27 7.27 0 0 1-2.264 1.434 7.221 7.221 0 0 1-2.697.515c-.066 0-.135 0-.203-.003-1.836.038-3.688-.587-5.144-1.931L64.468 111.25a7.292 7.292 0 0 1-.413-10.305 7.291 7.291 0 0 1 10.305-.413l18.547 17.118V59.185a7.293 7.293 0 1 1 14.586 0v58.098l18.148-16.749a7.293 7.293 0 0 1 9.892 10.717z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          fill={props.color}
        ></path>
      </g>
    </svg>
  );
};
