import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import { Container, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";

export default function Safety() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <LinearColorBack>
        <Container>
          <HeaderLogoWrap>
            <img src={newibeorlogo} alt="LOGO" onClick={() => navigate("/")} />
          </HeaderLogoWrap>
          <SafetyWrapper>
            <h5 style={{ margin: 0 }}>Thanks for Joining iBeor</h5>
            <p style={{ textAlign: "center" }}>
              To keep iBeor fun, we'd like you to know our social safety tips:
            </p>
          </SafetyWrapper>
        </Container>
      </LinearColorBack>
      <FooterItemsBox className="footer-item-wrapper">
        <div>
          <h2>Keep it clean:</h2>
          <span>
            Explicit or suggestive content will result in the removal of your
            iBeor Account and suspension.
          </span>
        </div>
        <div>
          <h2>Report Abuse:</h2>
          <span>
            If you see something inappropriate, please report it as soon as
            possible.
          </span>
        </div>
        <div>
          <h2>Block people:</h2>
          <span>
            If someone is bothering you, block them! They won't be able to
            contact you ever again.
          </span>
        </div>
        <div>
          <h2>Posts are public:</h2>
          <span>
            Don't post anything you'll regret in the near future. Don't give out
            personal information so easily.
          </span>
        </div>
        <div>
          <h2>Be careful meeting in real:</h2>
          <span>
            If you do meet in person, choose a public place or area, Like the
            mall or restaurant.
          </span>
        </div>
        <div>
          <h2>Contact Law Enforcement:</h2>
          <span>
            If you're threatened or if someone is in danger, contact your local
            police.
          </span>
        </div>

        <h1>iBeor DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS </h1>
      </FooterItemsBox>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const SafetyWrapper = styledComponents.div`

h5 {
    text-align: center;
    font-family: "Nunito Sans",sans-serif;
    list-style: none;
    font-size: 56px;
    line-height:79px;
    font-weight: 500;
    margin: 20px 0;
    color:#333;

    @media (max-width:580px){
      font-size:46px;
    }
    @media (max-width:470px){
      font-size:36px;
    }
}


p {
  font-family: "Nunito Sans",sans-serif;
  list-style: none;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  margin: 20px 0;
  padding: 0 0 40px 0;
  color:#000;
}


`;

const LinearColorBack = styledComponents.div`
width:100%;
margin:120px 0 20px 0;
margin-top: 0;

background: radial-gradient(circle at 3.6751302083333335% 6.940104166666666%, #4D6001 0%, 11.55%, rgba(77,96,1,0) 35%), radial-gradient(circle at 92.96712239583333% 9.554036458333332%, #FAAA63 0%, 17.5%, rgba(250,170,99,0) 35%), radial-gradient(circle at 95.20670572916666% 90.48177083333333%, #C9C6FF 0%, 34.1%, rgba(201,198,255,0) 55%), radial-gradient(circle at 4.313151041666666% 93.34635416666667%, #97B49D 0%, 29.900000000000002%, rgba(151,180,157,0) 65%), radial-gradient(circle at 46.555989583333336% 11.50390625%, rgba(214,222,72,0.99) 0%, 25%, rgba(214,222,72,0) 50%), radial-gradient(circle at 51.121419270833336% 89.98697916666667%, #93C3FF 0%, 42%, rgba(147,195,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%);


`;

const FooterItemsBox = styledComponents.div`
width:65%;
margin: auto;
div{
    display: flex;
}
h2 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    min-width: 270px;
    margin: 20px 30px 20px 0 ;
    color:#000;
}
span{
     font-family: "Nunito Sans", sans-serif;
list-style: none;
font-size: 17px;
font-weight: 400;
line-height: 22px;
margin: 20px 0 ;
color:#333;

}

h1 {
    font-family: "Nunito Sans", sans-serif;
    list-style: none;
    font-size: 29px;
    font-weight: 900;
    line-height: 50px;
    margin: 30px 0;
    color:#000;

}
`;
