import React, { useLayoutEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import { FooterContainer, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";

export default function CommunityGuidelines() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={newibeorlogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>
        <CommunityWrapper>
          <h4>Community Guidelines</h4>
          <p>
            Welcome to the IBEOR community. If you’re honest, kind and
            respectful to others, you’ll always be welcome here. If you choose
            not to be, you may not last. Our goal is to allow users to express
            themselves freely as long as it doesn’t offend others. Everyone is
            held to the same standard on IBEOR. We’re asking you to be
            considerate, think before you act, and abide by our community
            guidelines both on and offline. You heard that right: your offline
            behavior can lead to termination of your IBEOR account.
            <br />
            Below is a list of our community policies. If you violate any of
            these policies, you might be banned from IBEOR. Once you are banned,
            you are not allowed back into our community. We encourage you to
            report any behavior that violates our policies, and read up on our
            Safety Tips.
          </p>

          <h4>IBEOR is not for:</h4>
          <p>
            <b>Nudity/Sexual Content</b>
          </p>
          <p>
            We’re not asking you to comb your hair to one side or even speak in
            full sentences; but please keep it classy and appropriate for public
            consumption. No nudity, no sexually explicit content, and don’t
            chronicle all of your sexual desires in your bio. Keep it clean.
          </p>
          <p>
            <b>Harassment</b>
          </p>
          <p>
            Do not engage, or encourage others to engage, in any targeted abuse
            or harassment against any other user. This includes sending any
            unsolicited sexual content to your matches. Reports of stalking,
            threats, bullying, or intimidation, are taken very seriously.
          </p>

          <p>
            <b>Violence and Physical Harm</b>
          </p>

          <p>
            We do not tolerate violent, graphic, or gory content on IBEOR, or
            any actions or content that advocate for or threaten violence of any
            sort, including threatening or promoting terrorism. Physical
            assault, coercion, and any acts of violence are strictly prohibited.
            <br />
            <br />
            Content that advocates for or glorifies suicide or self-harm is also
            not allowed. In these situations, we may take a number of steps to
            assist the user, including reaching out with crisis resources.
          </p>
          <p>
            <b>Hate Speech</b>
          </p>
          <p>
            Any content that promotes, advocates for, or condones racism,
            bigotry, hatred, or violence against individuals or groups based on
            factors like (but not limited to) race, ethnicity, religious
            affiliation, disability, gender, age, national origin, sexual
            orientation, or gender identity is not allowed.
          </p>

          <p>
            <b>Private Information</b>
          </p>

          <p>
            Don’t publicly broadcast any private information, yours or anyone
            else’s. This includes social security numbers, passports, passwords,
            financial information or unlisted contact information, such as phone
            numbers, email addresses, home/work address.
          </p>

          <p>
            <b>Spam</b>
          </p>
          <p>
            Don’t be fake. Be real instead. Don’t use IBEOR to drive people to
            external websites via a link or otherwise.
          </p>

          <p>
            <b>Promotion or Solicitation</b>
          </p>
          <p>
            Soliciting other users is prohibited on IBEOR. It’s fine to invite
            your matches to something that you’re doing, but if the purpose of
            your profile is to advertise your event or business, non-profit,
            political campaign, contest, or to conduct research, we may delete
            your account. While we’re excited that you’re doing a comedy show
            next week, please don’t use IBEOR to promote it.
          </p>

          <p>
            <b>Prostitution and Trafficking</b>
          </p>

          <p>
            Promoting or advocating for commercial sexual services, human
            trafficking or other non-consensual sexual acts is strictly
            prohibited and will result in your account being banned from IBEOR.
          </p>
          <p>
            <b>Scamming</b>
          </p>
          <p>
            IBEOR has a zero-tolerance policy on predatory behavior of any kind.
            Anyone attempting to get other users’ private information for
            fraudulent or illegal activity may be banned. Any user caught
            sharing their own financial account information (PayPal, Venmo,
            etc.) for the purpose of receiving money from other users may also
            be banned from IBEOR.
          </p>
          <p>
            <b>Impersonation</b>
          </p>

          <p>
            Be yourself! Don’t pretend to be someone else.
            <br />
            Do not impersonate, or otherwise misrepresent affiliation,
            connection or association with, any person or entity. This includes
            parody accounts. While we think your Mike Pence profile is
            hilarious, you aren’t Mike Pence. And if you are, what are you doing
            on IBEOR?
          </p>

          <p>
            <b>Minors</b>
          </p>

          <p>
            You must be 18 years of age or older to use IBEOR. As such, we do
            not allow images of unaccompanied minors. If you want to post photos
            of your children, please make sure that you are in the photo as
            well. If you see a profile that includes an unaccompanied minor,
            encourages harm to a minor, or depicts a minor in a sexual or
            suggestive way, please report it immediately.
          </p>

          <p>
            <b>Copyright and Trademark Infringement</b>
          </p>
          <p>
            If it’s not yours, don’t post it. If your IBEOR profile includes any
            work that is copyrighted or trademarked by others, don’t display it,
            unless you are allowed to do so.
          </p>
          <p>
            <b>Illegal Usage</b>
          </p>

          <p>
            Don’t use IBEOR to do anything illegal. If it’s illegal IRL, it’s
            illegal on IBEOR.
            <br />
            One Person, One Account
            <br />
            IBEOR accounts cannot have multiple owners, so don’t create an
            account with your friend or significant other. Additionally, please
            don’t maintain multiple IBEOR accounts.
          </p>
          <p>
            <b>Third Party Apps</b>
          </p>
          <p>
            The use of any apps created by anyone other than IBEOR that claim to
            offer our service or unlock special IBEOR features is not allowed.
          </p>
          <p>
            <b>Account Dormancy</b>
          </p>

          <p>
            IBEOR is fun to use... all the time! Use IBEOR at the lake, use
            IBEOR while eating cake. Use IBEOR when you’re out, use IBEOR when
            in doubt! But, if you don’t log in to your IBEOR account in 2 years,
            we may delete your account for inactivity.
          </p>

          <h4>REPORT ALL BAD BEHAVIOR</h4>

          <p>
            <p>
              <b>On IBEOR:</b>
            </p>
            While on site, open a user’s profile, scroll to bottom, tap the
            ‘Report’ button and make a selection to send us a quick confidential
            report.
          </p>

          <p>
            <b>Off IBEOR:</b>
          </p>
          <p>
            If need be, contact local law enforcement, then contact us here.
          </p>
          {/* <h4>
            <a href="https://www.blk-app.com/en/safety-tips">
              CLICK HERE FOR TIPS ON DATING SAFETY.
            </a>
          </h4> */}

          <p>
            IBEOR reserves the right to investigate and/or terminate your
            account without a refund of any purchases if you have misused the
            Service or behaved in a way that IBEOR regards as inappropriate,
            unlawful, or in violation of the Terms of Use, including actions or
            communications that occur off the Service but involve users you meet
            through the Service.
          </p>
        </CommunityWrapper>
      </FooterContainer>
      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const CommunityWrapper = styledComponents.div`

h4 {
  font-family: "Nunito Sans",sans-serif;
    list-style: normal;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin: 10px 0 ;
    color:#000;
  }

.span {
  font-family: "Nunito Sans";
  list-style: normal;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  margin: 30px 0 ;
  color:#000;
}

h3 {
  text-align:center;
  font-family: 'playfair display',sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color:#000;
  margin: 20px 0;
}

p {
  font-family: "Nunito Sans",sans-serif;
    list-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    margin: 10px 0 ;
    color:#505965;
  
  }

h2 {
  text-align:center;
  font-family: 'playfair display',sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color:#000;
  margin: 20px 0;

}




`;
