/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CopyRight,
  FooterWrapper,
  GetTheApp,
  MainFooterContainer,
  SingleLine,
  SocialMediaIcons,
} from "../GobalStyle";
import {
  AppleStore,
  facebook,
  GooglePlay,
  instagram,
  tiktok,
  twitter,
  youtube,
} from "../Utils/Images";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const date = new Date();

  return (
    <>
      <MainFooterContainer>
        <FooterWrapper>
          <ul>
            LEGAL
            <li onClick={() => navigate("/privacy")}>Privacy</li>
            <li onClick={() => navigate("/terms")}>Terms</li>
            <li onClick={() => navigate("/policy")}>Cookie Policy</li>
            <li onClick={() => navigate("/security")}>Security</li>
          </ul>

          <ul>
            CONNECT
            <li onClick={() => navigate("/partnership")}>Partner with us</li>
            <li onClick={() => navigate("/contactus")}>Contact Us</li>
          </ul>

          <ul>
            COMMUNITY
            <li onClick={() => navigate("/safety")}>Safety</li>
            <li onClick={() => navigate("/safetytips")}>Safety Tips</li>
            <li onClick={() => navigate("/faq")}>FAQS</li>
            <li onClick={() => navigate("/communityguidelines")}>
              Community Guidelines
            </li>
          </ul>

          <ul
            style={{
              textAlign: "center",
            }}
          >
            SOCIAL
            <SocialMediaIcons>
              <a href="https://www.facebook.com/ibeorofficial/">
                <img src={facebook} alt="facebook" />
              </a>

              <a href="https://twitter.com/official_ibeor/">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="https://www.youtube.com/channel/UCNhNYxP-MyC_xuZTmPZKYIQ/">
                <img src={youtube} alt="youtube" />
              </a>
              <a href="https://www.instagram.com/official_ibeor/">
                <img src={instagram} alt="instagram" />
              </a>
              <a href="https://www.tiktok.com/@official_ibeor/">
                <img src={tiktok} alt="tiktok" />
              </a>
            </SocialMediaIcons>
          </ul>
        </FooterWrapper>
        <SingleLine />
        <GetTheApp>
          <h2>GET THE APP!</h2>
          {location.pathname === "/ibeor-blacks" ? (
            <>
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843"
              >
                <img src={AppleStore} alt="Apple Store" />
              </a>

              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US&pli=1"
              >
                <img src={GooglePlay} alt="Google Play" />
              </a>
            </>
          ) : (
            <>
              {" "}
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/ibeor-dating-app/id1610780497"
              >
                <img src={AppleStore} alt="Apple Store" />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.ibeor.official&hl=en_IN&gl=US"
              >
                <img src={GooglePlay} alt="Google Play" />
              </a>
            </>
          )}
        </GetTheApp>
        <hr />
      </MainFooterContainer>
      <CopyRight>
        <span>
          © Copyright {date.getFullYear()} iBeor. All rights reserved.
        </span>
      </CopyRight>
    </>
  );
}
