import React, { useState } from 'react'
import styled from 'styled-components';

import { IoMdMenu } from "react-icons/io";
import { Drawer } from 'antd';
import {  newibeorlogo } from '../Utils/Images';
import { useNavigate } from 'react-router-dom';

export default function Mobileheader() {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
      };
    
      const onClose = () => {
        setOpen(false);
      };

      const style={
       margin:"20px 0px",
       fontSize:"22px",
       borderBottom:"1px solid grey",
      }
 
      const navigate = useNavigate();

  return (
    <Wrapper>
        <div>
        <IoMdMenu onClick={showDrawer} fontSize={30} color='white'/>
        <Drawer title={<DrawerTilte>
            <div>
            <img src={newibeorlogo} alt="iBeorLogo" />

            </div>
            <div>
            <a href='https://blacks.ibeor.com' >

            <button className='download-btn'  >Download</button>
            </a>

            </div>

        </DrawerTilte> }placement="left"  onClose={onClose} open={open} prefixCls='mobile-drawer'>
        <p style={style} onClick={() => navigate("/ibeor-blacks")}>iBeor black</p>
        <p style={style} onClick={() => navigate("/ambassadors")}>$ Affiliate</p>
        <p style={style} onClick={() => navigate("/advertise")}>Advertise with iBeor</p>
      </Drawer>


        </div>
        <div className='ibeorlogo'>
      
            <img src={newibeorlogo} onClick={() => navigate("/")} alt="iBeorLogo" />
          

        </div>
        <div>
            <a href='https://blacks.ibeor.com' >

            <button className='download-btn'  >Download</button>
            </a>

        </div>
    </Wrapper>
  )
}
const Wrapper=styled.div`
display:flex;
padding:0px 10px;
background-color:#d4406c;
justify-content:space-between;
align-items:center;
.ibeorlogo{
    width:100px;
    
   
    img{
        width:100%;
        height:25px;
        margin:10px;
     

    }
}
.download-btn{
    padding: 5px 10px;
    font-size:14px;
    border-radius:99px;
}
`
const DrawerTilte=styled.div`
display:flex;
justify-content:space-around;
align-items:center;

img{
    width:100px;
    height:25px;
    margin:10px;
 

}
.download-btn{
    padding: 5px 10px;
    font-size:14px;
    border-radius:99px;
}

`
