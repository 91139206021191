import "./App.css";
import AllRoutes from "./Routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import PreloadImages from "./Utils/PreLoadingImages";
import {
  AdvertiseGridImg,
  AdvertiseMiddle,
  AdvertiseUpper,
  Africaiphone,
  CashPromoting,
  DownloadBack,
  MainCoverImage1,
  image2,
  iphonePoster,
  slider1,
} from "./Utils/Images";
import { SplashScreen } from "./Components/SplashScreen";
import { useCallback, useEffect, useMemo, useState } from "react";

const images = [
  iphonePoster,
  MainCoverImage1,
  image2,
  AdvertiseUpper,
  Africaiphone,
  AdvertiseMiddle,
  slider1,
  CashPromoting,
  AdvertiseGridImg,
  DownloadBack,
];

function App() {
  const sessionLoading = sessionStorage.getItem("loading");
  const [loading, setLoading] = useState(sessionLoading == null ? true : false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoading(sessionLoading === "false" ? false : true);
    const timeout = setTimeout(() => {
      setLoading(false);
      sessionStorage.setItem("loading", "false");
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const images = document.getElementsByTagName("img");
    const totalImages = images.length;

    function checkAllImagesLoaded() {
      let loadedImages = 0;
      for (let i = 0; i < totalImages; i++) {
        if (images[i].complete) {
          loadedImages += 1;
        }
      }
      if (loadedImages === totalImages) {
        setTimeout(() => {
          setLoad(false);
        }, 1200);
      }
    }

    for (let i = 0; i < images.length; i++) {
      images[i].addEventListener("load", checkAllImagesLoaded);
      images[i].addEventListener("error", checkAllImagesLoaded);
    }

    return () => {
      for (let i = 0; i < images.length; i++) {
        images[i].removeEventListener("load", checkAllImagesLoaded);
        images[i].removeEventListener("error", checkAllImagesLoaded);
      }
    };
  }, []);

  return (
    <div>
      {(loading || load) && <SplashScreen isUnMount={load} />}
      <PreloadImages images={images} />
      <AllRoutes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
