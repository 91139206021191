import React from "react";
import { useLocation } from "react-router-dom";
import styledComponents from "styled-components";
import { newibeorlogo } from "../Utils/Images";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <HeaderBox>
      <Headermain>
        <div>
          <HeaderWrapper>
            {/* <a href="/"> */}
            <img
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              src={newibeorlogo}
              alt="iBeorLogo"
              // loading="eager"
            />
            {/* </a> */}
          </HeaderWrapper>
        </div>
        {location.pathname === "/" ||
        location.pathname === "/ibeor-blacks" ||
        location.pathname === "/ambassadors" ? (
          <div>
            <Headercontent>
              <ul>
                <li onClick={() => navigate("/ibeor-blacks")}>
                  <b>iBeor Blacks</b>
                </li>
                <li onClick={() => navigate("/ambassadors")}>
                  <b> $ Affiliate</b>
                </li>
                <li onClick={() => navigate("/advertise")}>
                  <b>Advertise with iBeor</b>
                </li>
                <li onClick={() => navigate("/download")}>
                  <b>Download</b>{" "}
                </li>
                {location.pathname === "/ambassadors" && (
                  <li className="signup">
                    <b>Sign up </b>
                  </li>
                )}
              </ul>
            </Headercontent>
          </div>
        ) : (
          ""
        )}
      </Headermain>
    </HeaderBox>
  );
}

const HeaderBox = styled.div`
  width: 72%;
  height: 100%;
  margin: auto;

  @media (max-width: 925px) {
    width: 100%;
  }
`;

const HeaderWrapper = styledComponents.div`
width:100%;
height: 85px;
display: flex;
flex-direction: column;
justify-content: center;

img {
  width: 220px;
  object-fit: contain;
  padding:8px;
}
@media (max-width: 1400px) {
  height: 75px;

  img {
    width: 180px;
    object-fit: contain;
    }
}
`;
const Headercontent = styledComponents.div`
width: 100%;
display: flex;
align-items: baseline;
gap: 10px;

ul{
  list-style:none;
  font-style:none;
  display:flex;
  margin-top:11px;
  cursor: pointer;
}
li{
  border-Right: 2px solid black;
  font-style: normal;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  font-family: poppins-semibold, poppins, sans-serif;
  padding: 0 8px;
  
}
.signup{
  color : #5182ff;
}
li:hover{
  color: #cc2643;
}
@media(max-width:820px){
 ul{
  list-style:none;
  padding-left:0rem;
 }
  li{
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  font-family: poppins-semibold, poppins, sans-serif;
  }
}
@media (max-width:768px){
  li{
    font-size: 15px;
  }
}
@media(min-width:420px) and (max-width:674px){
  display: flex;
  justify-content: center;
  ul{
   list-style:none;
   padding-left:0rem;
  }
   li{
     font-style: normal;
   font-weight: 700;
   font-size: 13px;
   line-height: 28px;
   font-family: poppins-semibold, poppins, sans-serif;
   }
 }


 
 @media (max-width:490px){
  width: 100%;
  display: flex;
  justify-content: center;
  ul{
   list-style:none;
   padding-left:0rem;
  }
   li{
     font-style: normal;
   font-weight: 700;
   font-size: 12px;
   line-height: 28px;
   font-family: poppins-semibold, poppins, sans-serif;
   }
 }

 @media(max-width:458px){
  width: 100%;
  display: flex;
  justify-content: center;
  ul{
   list-style:none;
   padding-left:0rem;
  }
   li{
     font-style: normal;
   font-weight: 500;
   font-size: 9px;
   line-height: 28px;
   font-family: poppins-semibold, poppins, sans-serif;
   }
 }

 @media(max-width:371px){
  li{
    font-size: 7px;
  }
}

`;
const Headermain = styledComponents.div`
width: 100%;
display:flex;
justify-content:space-between;
align-items:center;
align-content:center;
flex-wrap: wrap;
@media(max-width:820px){
flex-wrap:wrap;
}
`;

// const SignUpButton = styled.button`
//   background-color: ${(props) => props?.background || "#5182ff"};
//   border: 0px solid #5182ff;
//   letter-spacing: 0.05em;
//   font-weight: 700;
//   color: ${(props) => props.color || "#ffffff"};
//   font-size: 19px;
//   padding: 4px 30px;
//   border-radius: ${(props) => props?.radius || "20px"};
//   height: fit-content;
//   cursor: pointer;
//   box-shadow: ${(props) => props?.shadow || "none"};
// `;
