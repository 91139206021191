import React from "react";
import styled from "styled-components";
import logo_anim from "../lotties/logo_anim.json";
import Lottie from "react-lottie";

export const SplashScreen = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: logo_anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <SplashScreeBox>
      <Lottie options={defaultOptions} height={"100vh"} width={"100vw"} />
    </SplashScreeBox>
  );
};

const SplashScreeBox = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background: #000;
  overflow: hidden;
`;
