import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import { FooterContainer, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";

export default function FAQS() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={newibeorlogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>
        <FAQWrapper>
          <h4>Report a Concern</h4>
          <p>
            You can report a concern by navigating to a user’s profile tapping
            the three dots and selecting report a concern. Select the option
            that best represents your concern and submit. We take our
            communities safety seriously. Please report bad behavior,
            inappropriate photos and scammers. All reports are anonymous and
            your identity will not be shared with the person you are reporting.
          </p>

          <h4>Block a Member</h4>
          <p>
            To Block a member, navigate to the user’s profile tap the three dots
            and tap block member. When a member is blocked, you will no longer
            see that person in your matches and your communication with that
            member will be deleted. Additionally, you will no longer be visible
            to the person you blocked.
          </p>
          <h4>Unblock a Member</h4>
          <p>
            Blocking a member is permanent. Once a member is blocked, you may
            not unblock them.
          </p>
          <h4>Photos and Profiles</h4>
          <p>
            <b>How to Upload a Photo</b>
          </p>
          <p>
            Photos can be uploaded from Facebook, your device or you can take a
            selfie on the fly.
          </p>
          <p>
            <b>To upload a photo:</b>
          </p>
          <ul style={{ margin: 0 }}>
            <li>Tap the profile icon in the top nav</li>
            <li>Tap the edit profile icon</li>
            <li>Tap the add button</li>
            <li>Select the photo you wish to upload</li>
          </ul>

          <p>
            <b>How to Delete a Photo</b>
          </p>
          <ul style={{ margin: 0 }}>
            <li>Tap the profile icon in the top nav</li>

            <li>Tap the edit profile icon</li>
            <li>Tap the X button</li>
            <li>
              You must have at least one photo at all times. If you attempt to
              delete your last photo, you will need to replace the photo with a
              different one.
            </li>
          </ul>
          <p>
            <b>Why was my Photo Removed?</b>
          </p>
          <p>
            Your photo was reviewed and did not meet our apps photo
            requirements. You can upload a new photo that meets our guidelines.
          </p>

          <p>
            <b>Photo Dos & Don’ts</b>
          </p>
          <p>
            Think before you post. Posting an inappropriate photo is grounds to
            be banned.
          </p>
          <p>
            <b>Photo Dos</b>
          </p>
          <ul style={{ margin: 0 }}>
            <li>Show off your smile</li>
            <li>Post recent photos looking your best</li>
            <li>Show off photos of you doing things you love</li>

            <li>Include photos of your pets</li>

            <p>
              <b>Photo Don’ts</b>
            </p>

            <ul style={{ margin: 0 }}>
              <li>No nude photos</li>
              <li>No photos depicting violence</li>
              <li>No copyrighted photos</li>
              <li>No photos of celebrities</li>
            </ul>
          </ul>

          <h4>Communication</h4>
          <p>
            <b>How to Send a Message</b>
          </p>
          <p>
            Before you can send a message, you will need to show interest in
            each other by sliding right. Once there is a mutual match you can
            start chatting. To start a conversation, tap the chat icon in the
            top nav, tap the person’s photo and send a message.
          </p>

          <h4>Matches</h4>
          <p>
            <b>How to Delete a Connection</b>
          </p>

          <p>
            To delete a connection, long press on the profile photo of the
            connection you would like to delete and tap Delete Connection.
          </p>
          <p>
            <b>How to Delete Conversation</b>
          </p>

          <p>
            To delete a conversation, slide the conversation to the left and tap
            delete.
          </p>
          <p>
            <b>My Match Disappeared</b>
          </p>
          <p>
            Sounds like the person may have unmatched with you, or deleted their
            account.
          </p>

          <h4>Support</h4>

          <p>
            Need additional help or want to tell us how much fun you are having
            with IBEOR? Send us a line! Any support related questions, comments,
            or feedback can be emailed to support@ibeor-app.com.
          </p>
          <h4>Access and Settings</h4>

          <p>
            <b>How to logout</b>
          </p>
          <ul style={{ margin: 0 }}>
            <li>Tap the profile icon in the top nav</li>
            <li>Tap the gear icon to access your settings</li>
            <li>Tap Logout button to temporarily sign out of the app.</li>
            <li>Logging out will not hide your profile.</li>
          </ul>

          <p>
            <b>How to Delete My Account</b>
          </p>

          <ul style={{ margin: 0 }}>
            <li>Tap the profile icon in the top nav</li>
            <li>Tap the gear icon to access your settings</li>
            <li>Tap delete button to completely remove your account</li>
            <li>Deleting your account will remove all of your connections.</li>
          </ul>

          <h4>Can’t Login / My App Crashed</h4>
          <p>
            This is probably temporary, so check back later. If you are still
            having trouble, try reinstalling the IBEOR app. To reinstall, delete
            the app then download the app again. As long as you do not delete
            your account this will not affect your matches.
          </p>

          <h4>How do I request a copy of my personal data?</h4>

          <p>
            At IBEOR, we are committed to protecting your data and privacy as
            well as providing you access to the information you have provided
            us.
            <br />
            We are currently receiving higher call volumes and are working hard
            to answer each call as quickly as possible while caring for the
            health and safety of our team members. For a quicker response,
            please contact us through email.
            <br />
            If you would like to request a copy of your personal data, please
            contact by at support@ibeor-app.com and someone will get back to you
            within 48 hours.
            <br />
            <br />
            The information you will receive through this request is largely
            already available to you through the web site/app and may vary,
            depending on the way you have used IBEOR.
            <br />
            <br />
            If you have deleted your IBEOR account, you will not be able to
            access this data, as you no longer have an account on IBEOR. After
            you have deleted your account, your data is disposed of in
            accordance with our privacy policy.
          </p>
        </FAQWrapper>
      </FooterContainer>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const FAQWrapper = styledComponents.div`
padding: 20px 10px;

h1 {
  text-align:left;
  font-family: "Nunito Sans",sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 52px;
  color:#000;
  margin: 20px 0;
}

 p {
  font-family: "Nunito Sans",sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color:#505965;
  margin: 20px 0;

 }



  h4 {
    font-family: "Nunito Sans",sans-serif;
      list-style: normal;
      font-size: 28px;
      font-weight: 700;
      line-height: 38px;
      margin: 10px 0 ;
      color:#000;
 }

 ul {
  font-family: "Nunito Sans",sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  display: inline-block;
list-style-type:none;
  margin: 20px 30px 20px 0 ;
  color:#505965;
  margin: 20px 0;
  padding:0px;
 }


 @media (max-width: 500px) {
padding: 20px 18px;

  h1 {
    font-size: 24px;
    margin: 10px 0;
    line-height: 32px;
    font-weight:700;
  }

  p {
    margin:2px 0;
  }

  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    font-weight:500;
    color:#000;
    margin: 10px 0;
  
   }

 }


`;
