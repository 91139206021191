/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import {
  Container,
  Container1,
  AfricaCover,
  Topwrapper,
  HomeIphonePoster,
  HomeDownloadiBeor,
  AfricaBanner,
  HomeIphonePosterHide,
  HowItWorks,
  HeaderContainer,
} from "../GobalStyle";
import Slider from "react-slick";
// import Africaiphone from "../Images/Africaiphone.webp";
import {
  Africaiphone,
  AppleStore,
  GooglePlay,
  HowItWorkPoster1,
  HowItWorkPoster2,
  HowItWorkPoster3,
  slider1,
} from "../Utils/Images";

import styled from "styled-components";
import Download from "./Download";
// import slider1 from "../Images/slider1.webp";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
import ResetPhoneNumber from "./ResetPhoneNumber";
import ForgetPassword from "./ForgetPassword";
import Mobileheader from "../Components/Mobileheader";

export default function Africa() {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const queryToken = window?.location?.search?.substring(1)?.split("=")?.[1];
  const getParamsWordPassword = window?.location?.search?.includes("password");
  const getParamsWordToken = window?.location?.search?.includes("token");
  const HowItWorksArray = [
    {
      header: "Create A Profile",
      body: "Create your profile in seconds with our easy sign-up. ",
      image: HowItWorkPoster1,
    },

    {
      header: "Browse Photos",
      body: "Search our large member base with ease, with a range of preferences and settings.",
      image: HowItWorkPoster2,
    },

    {
      header: "Start Communicating",
      body: "Send a private message or interest to start communicating with members. It's your time to shine.",
      image: HowItWorkPoster3,
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    console.log(window, "wwwwwww");

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {getParamsWordToken &&
        queryToken !== null &&
        queryToken !== undefined && <ResetPhoneNumber token={queryToken} />}

      {getParamsWordPassword &&
        queryToken !== null &&
        queryToken !== undefined && <ForgetPassword token={queryToken} />}

      <HeaderContainer>
        {windowWidth > 750 ? <Header /> : <Mobileheader />}
      </HeaderContainer>
      <AfricaCover>
        <div>
          <Container1>
            <Topwrapper>
              <Mainheading>
                {/* <h2>
                  iBeor is the Largest African & Blacks Dating App. Join Today &
                  Start Dating Singles near You!
                </h2>
                <h1>Meet African Singles near You.</h1>
                <h3>THE #1 BEST AFRICAN DATTING & SINGLES</h3> */}
                <h2>
                  #1 Dating app for Black Singles. Join Today & Start Dating
                  Singles near You!
                </h2>
                <h3>
                  Match, Date, Meet Black Singles & Find Relationship nearby and
                  make friends.
                </h3>
                <HomeIphonePosterHide src={Africaiphone} alt="Iphone" />
              </Mainheading>

              <Storeicon>
                <h1>GET THE APP</h1>
                <div className="getAppLinks">
                  <a
                    target="_blank"
                    // href="https://apps.apple.com/us/app/ibeor-meet-black-singles/id1610779843"
                    href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843"
                  >
                    <HomeDownloadiBeor src={AppleStore} alt="Download" />
                  </a>
                  <a
                    target="_blank"
                    // href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US"
                    href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US&pli=1"
                  >
                    <HomeDownloadiBeor src={GooglePlay} alt="Download" />
                  </a>
                </div>
              </Storeicon>
            </Topwrapper>
            <HomeIphonePoster src={Africaiphone} alt="Iphone" />
          </Container1>
        </div>
      </AfricaCover>
      <div>
        <Slider {...settings}>
          <img src={slider1} alt="slider1" />
        </Slider>
      </div>
      {/* <HowItWorks>
        <div className="headerWrapper">
          <header>How It Works</header>
          <p>Get started on iBeor.com today in 3 simple steps:</p>
        </div>
        <div className="contentWrapper">
          {HowItWorksArray?.map((el) => (
            <div>
              <img src={el.image} alt="poster images" />
              <h1>{el.header}</h1>
              <p>{el.body}</p>
            </div>
          ))}
        </div>
      </HowItWorks> */}
      <HowItWorks>
        <div className="headerWrapper">
          <header>How It Works</header>
          <p>Get started on iBeor.com today in 3 simple steps:</p>
        </div>
        <div className="contentWrapper">
          {HowItWorksArray?.map((el) => (
            <div>
              <img src={el.image} alt="poster images" />
              <h1>{el.header}</h1>
              <p>{el.body}</p>
            </div>
          ))}
        </div>
      </HowItWorks>
      <AfricaBanner>
        <div>
          <Container1>
            <Textfield>
              <div className="textWapper">
                <div className="left-txt-desc">
                  <h1>Date Traditional Women</h1>
                  {/* <p>Date African Traditional-</p>
                  <p>Cultural Men and Women right</p>
                  <p>here on iBeor Dating App</p> */}
                  <p>Date Traditional - Cultural Men and</p>
                  <p>Women right here on iBeor Dating App</p>
                </div>
                <div className="find-something-text">
                  <h1>Find something real</h1>
                  <h4>
                    Over 90% of iBeor Daters are looking for <br></br>a serious
                    relationship.can we introduce <br></br>you? Download iBeor
                    Dating App Now!!
                  </h4>
                  <icons>
                    <a
                      target="_blank"
                      // href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843"
                      href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843"
                    >
                      <HomeDownloadiBeor src={AppleStore} alt="Download" />
                    </a>
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US"
                      href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US&pli=1"
                    >
                      <HomeDownloadiBeor src={GooglePlay} alt="Download" />
                    </a>
                  </icons>
                </div>
              </div>
            </Textfield>
          </Container1>
        </div>
      </AfricaBanner>
      <Footer />
    </>
  );
}

const Mainheading = styled.div`
  width: 100%;
  h2 {
    font-family: poppins-semibold, poppins, sans-serif;
    font-size: 40px;
    color: white;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: start;
    font-weight: bold;
    margin: 40px;
    margin-left: 0;
  }
  h1 {
    font: normal normal normal 32px/1.41em "playfair display", serif;
    color: white;
    font-weight: bold;
    margin: 40px;
    margin-left: 0;
    margin-bottom: 10px;
  }
  h3 {
    font: normal normal normal 28px/1.375em "playfair display", serif;
    color: white;
    margin: 40px;
    margin-left: 0;
    margin-top: 10px;
  }
  @media (max-width: 1000px) {
    h2 {
      font-size: 30px;
      margin: 10px;
    }
    h1 {
      font: normal normal normal 24px/1.41em "playfair display", serif;
      margin: 20px;
    }
    h3 {
      font: normal normal normal 14px/1.375em "playfair display", serif;
      margin: 20px;
    }
  }
  @media (max-width: 1150px) {
    h2 {
      font-size: 30px;
      margin: 10px;
    }
    h1 {
      font: normal normal normal 24px/1.41em "playfair display", serif;
      margin: 20px;
    }
    h3 {
      font: normal normal normal 14px/1.375em "playfair display", serif;
      margin: 20px;
    }
  }
  @media (max-width: 800px) {
    h2 {
      font-size: 25px;
      margin-bottom: 15px;
    }
    h1 {
      font-size: 18px;
      margin: 0px 40px;
    }
    h3 {
      font-size: 20px;
      margin: 10px 40px;
    }
  }
`;
// const HomeDownloadicons = styled.div`
//   margin: 40px;
//   h1 {
//     font: normal normal normal 42px/1.2em "playfair display", serif;
//     color: white;
//   }
//   img {
//     margin-left: 15px;
//   }
//   @media (max-width: 920px) {
//     margin-top: 20px;
//     margin-bottom: 20px;
//     .getAppLinks {
//       display: flex;
//       justify-content: center;
//     }
//     h1 {
//       text-align: center;
//     }
//   }
// `;

const Textfield = styled.div`
display:flex;
width:100%;
justify-content: center;

.textWapper{
    display: flex; 
    gap: 4rem;

    @media (max-width: 1200px){
      gap: 1rem;
    }
    .find-something-text{
      
    }
    .left-txt-desc{
     
    }
    @media (max-width: 1100px) {
      display: block;
      gap: 3rem;
    }
    @media (max-width: 800px) {
        display: block;
       gap: 0;

       .left-txt-desc{
        width: 100%;
        text-align: center;
        h1{
            font-size: 33px;
        }
        p{
            font-size: 20px;
        }
       }
       .find-something-text{
        padding-top: 30px;
        text-align: center;
        width: 100%;
        h1{
            font-size: 35px;
            color: white;
        }
        h4{
            font-size: 20px;
        }
       }
      }
}

// padding-left:150px;




h1{
    color:white;
    font:normal normal normal 60px/1.25em 'playfair display',serif;
    font-weight:bold;
}
p{
    color:white;
    font: normal normal normal 28px/1.67em 'playfair display',serif;
    line-height:0.7;
    font-weight:900:
}
h4{
    color:white;
    font:normal normal normal 35px/1.25em 'playfair display',serif;
}
div{
   
    icons{
        margin-left:120px;
        display: flex;

        @media(max-width: 1150px){
        margin-left:0px;
        }
        @media(max-width: 1360px){
          margin-left:0px;
          }
    }
    img{
        margin-right:20px;
    }
    @media (max-width: 800px){
        padding-bottom: 1rem;
        icons{
            margin-left:0px;
            
        }
    }
}


`;
const AfricaIphonePoster = styled.img`
  display: block;
  position: absolute;
  height: auto;
  width: 400px;
  right: 0;
  bottom: 0;
  margin-bottom: -35px;

  @media (max-width: 1500px) {
    width: 350px;
    height: auto;
  }

  @media (max-width: 1400px) {
    width: 300px;
    height: auto;
  }
  @media (max-width: 1111px) {
    display: 250px;
    height: auto;
    display: none;
  }
  @media (max-width: 999px) {
    display: none;
  }
  @media (max-width: 669px) {
    display: none;
  }
`;

const AfricaIphonePosterHide = styled.img`
  display: none;

  @media (max-width: 1500px) {
    width: 350px;
    height: auto;
  }

  @media (max-width: 1400px) {
    width: 300px;
    height: auto;
  }
  @media (max-width: 1111px) {
    display: 250px;
    height: auto;
  }
  @media (max-width: 999px) {
    display: revert;
  }
`;
const Storeicon = styled.div`
  margin-top: 0px;

  @media (max-width: 1400px) {
    margin-top: 50px;
  }

  .getAppLinks {
    white-space: nowrap;
    display: flex;
    justify-content: start;
  }
  h1 {
    color: white;
    /* text-decoration: underline; */
    font: normal normal normal 42px/1.2em "playfair display", serif;
    font-weight: 900;
    text-align: left;
  }
  img {
    margin-right: 20px;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 20px 0;
  }
`;
