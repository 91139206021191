import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import {
  FooterContainer,
  FooterItems,
  HeaderLogoWrap,
  Heading,
  SingleLine,
  SmallTextPTAG,
} from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";

export default function Privacy() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={newibeorlogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>
        <FooterItems>
          <heading>Our Commitment To You</heading>
          <SmallTextPTAG>
            At iBeor, your privacy is a top priority. Your privacy is at the
            core of the way we design and build the services and products you
            know and love, so that you can fully trust them and focus on
            building meaningful connections.
          </SmallTextPTAG>
          <SmallTextPTAG>
            We appreciate that you put your trust in us when you provide us with
            your information and we do not take this lightly.
          </SmallTextPTAG>
          <SmallTextPTAG>
            <label>We do not compromise with your privacy.</label> We design all
            of our products and services with your privacy in mind. We involve
            experts from various fields, including legal, security, engineering,
            product design and others to make sure that no decision is taken
            without respect for your privacy.
          </SmallTextPTAG>

          <SmallTextPTAG>
            <label>
              We strive to be transparent in the way we process your data.
            </label>
            Because we use many of the same online services you do, we know that
            insufficient information and overly complicated language are common
            issues in privacy policies. We take the exact opposite approach: we
            have written our Privacy Policy and related documents in plain
            language. We actually want you to read our policies and understand
            our privacy practices!
          </SmallTextPTAG>

          <SmallTextPTAG>
            <label>We work hard to keep your information secure. </label>We have
            teams dedicated to keeping your data safe and secure. We constantly
            update our security practices and invest in our security efforts to
            enhance the safety of your information.
          </SmallTextPTAG>
        </FooterItems>

        <Heading style={{ marginBottom: "-20px" }}>* * *</Heading>
        <Heading>Privacy Policy</Heading>
        <FooterItems>
          <p>
            Welcome to iBeor’s Privacy Policy. Thank you for taking the time to
            read it.
          </p>

          <p>
            We appreciate that you trust us with your information and we intend
            to always keep that trust. This starts with making sure you
            understand the information we collect, why we collect it, how it is
            used and your choices regarding your information. This Policy
            describes our privacy practices in plain language, keeping legal and
            technical jargon to a minimum.
          </p>

          <span>1. Who We Are</span>
          <p>
            ​ If you live in the United States or Canada, the company that is
            responsible for your information under this Privacy Policy (the
            "data controller") is: iBeor, Inc 5429 Lyndon B Johnson Fwy Dallas,
            TX 75240, USA
          </p>
          <span>2. WHERE THIS PRIVACY POLICY APPLIES</span>
          <p>
            This Privacy Policy applies to websites, apps, events and other
            services operated by iBeor. For simplicity, we refer to all of these
            as our “services” in this Privacy Policy. To make it extra clear,
            we’ve added links to this Privacy Policy on all applicable services.
            Some services may require their own unique privacy policy. If a
            particular service has its own privacy policy, then that policy --
            not this Privacy Policy -- applies.
          </p>
          <span>3. Information We Collect</span>
          <p>
            It goes without saying, we can’t help you develop meaningful
            connections without some information about you, such as basic
            profile details and the types of people you’d like to meet. We also
            collect information generated as you use our services, for example
            access logs, as well as information from third parties, like when
            you access our services through a social media account. If you want
            additional info, we go into more detail below. ​
          </p>
          <h4>Information you give us</h4>
          <p>
            You choose to give us certain information when using our services.
            This includes:
          </p>
          <ul>
            <li>
              • When you create an account, you provide us with at least your
              login credentials, as well as some basic details necessary for the
              service to work, such as your gender and date of birth.
            </li>
            <li>
              • When you complete your profile, you can share with us additional
              information, such as details on your personality, lifestyle,
              interests and other details about you, as well as content such as
              photos and videos. To add certain content, like pictures or
              videos, you may allow us to access your camera or photo album.
              Some of the information you choose to provide us may be considered
              “special” or “sensitive” in certain jurisdictions, for example
              your racial or ethnic origins, sexual orientation and religious
              beliefs. By choosing to provide this information, you consent to
              our processing of that information.
            </li>
            <li>
              • When you subscribe to a paid service or make a purchase directly
              from us (rather than through a platform such as iOS or Android),
              you provide us or our payment service provider with information,
              such as your debit or credit card number or other financial
              information.
            </li>
            <li>
              • When you participate in surveys or focus groups, you give us
              your insights into our products and services, responses to our
              questions and testimonials.
            </li>
            <li>
              • When you choose to participate in our promotions, events or
              contests, we collect the information that you use to register or
              enter.
            </li>
            <li>
              • If you contact our customer care team, we collect the
              information you give us during the interaction. Sometimes, we
              monitor or record these interactions for training purposes and to
              ensure a high quality of service.
            </li>
            <li>
              • If you ask us to communicate with or otherwise process
              information of other people (for example, if you ask us to send an
              email on your behalf to one of your friends), we collect the
              information about others that you give us in order to complete
              your request.
            </li>
            <li>
              • Of course, we also process your chats with other users as well
              as the content you publish, as part of the operation of the
              services.
            </li>
          </ul>

          <h4>Information we receive from others</h4>
          <p>
            In addition to the information you provide us directly, we receive
            information about you from others, including:
          </p>
          <ul>
            <li>
              • Other Users Other users may provide information about you as
              they use our services. For instance, we may collect information
              about you from other users if they contact us about you.
            </li>
            <li>
              • Social Media You may be able to use your social media login
              (such as Facebook Login) to create and log into your iBeor
              account. This saves you from having to remember yet another user
              name and password and allows you to share some information from
              your social media account with us.
            </li>
            <li>
              • Other Partners We may receive info about you from our partners,
              for instance where iBeor ads are published on a partner’s websites
              and platforms (in which case they may pass along details on a
              campaign’s success).
            </li>
          </ul>

          <h4>Information collected when you use our services</h4>
          <p>
            When you use our services, we collect information about which
            features you’ve used, how you’ve used them and the devices you use
            to access our services. See below for more details:
          </p>
          <ul>
            Usage Information
            <li style={{ lineHeight: "1.8" }}>
              We collect information about your activity on our services, for
              instance how you use them (e.g., date and time you logged in,
              features you’ve been using, searches, clicks and pages which have
              been shown to you, referring webpage address, advertising that you
              click on) and how you interact with other users (e.g., users you
              connect and interact with, time and date of your exchanges, number
              of messages you send and receive).
            </li>
          </ul>

          <ul>
            Device information
            <li style={{ lineHeight: "1.8" }}>
              We collect information from and about the device(s) you use to
              access our services, including: hardware and software information
              such as IP address, device ID and type, device-specific and apps
              settings and characteristics, app crashes, advertising IDs (such
              as Google’s AAID and Apple's IDFA, both of which are randomly
              generated numbers that you can reset by going into your device’
              settings), browser type, version and language, operating system,
              time zones, identifiers associated with cookies or other
              technologies that may uniquely identify your device or browser
              (e.g., IMEI/UDID and MAC address); information on your wireless
              and mobile network connection, like your service provider and
              signal strength; information on device sensors such as
              accelerometers, gyroscopes and compasses.
            </li>
          </ul>

          <ul>
            Other information with your consent
            <li style={{ lineHeight: "1.8" }}>
              If you give us permission, we can collect your precise geolocation
              (latitude and longitude) through various means, depending on the
              service and device you’re using, including GPS, Bluetooth or Wi-Fi
              connections. The collection of your geolocation may occur in the
              background even when you aren’t using the services if the
              permission you gave us expressly permits such collection. If you
              decline permission for us to collect your geolocation, we will not
              collect it. Similarly, if you consent, we may collect your photos
              and videos (for instance, if you want to publish a photo, video or
              streaming on the services).
            </li>
          </ul>

          <span>4. Cookies and Other Similar Data Collection Technologies</span>
          <p>
            We use and may allow others to use cookies and similar technologies
            (e.g., web beacons, pixels) to recognize you and/or your device(s).
            You may read our Cookie Policy for more information on why we use
            them (such as authenticating you, remembering your preferences and
            settings, analyzing site traffic and trends, delivering and
            measuring the effectiveness of advertising campaigns, allowing you
            to use social features) and how you can better control their use,
            through your browser settings and other tools. Some web browsers
            (including Safari, Internet Explorer, Firefox and Chrome) have a “Do
            Not Track” (“DNT”) feature that tells a website that a user does not
            want to have his or her online activity tracked. If a website that
            responds to a DNT signal receives a DNT signal, the browser can
            block that website from collecting certain information about the
            browser’s user. Not all browsers offer a DNT option and DNT signals
            are not yet uniform. For this reason, many businesses, including
            iBeor, do not currently respond to DNT signals.
          </p>
          <span>5. How We Use Information</span>
          <p>
            The main reason we use your information is to deliver and improve
            our services. Additionally, we use your info to help keep you safe
            and to provide you with advertising that may be of interest to you.
            Read on for a more detailed explanation of the various reasons we
            use your information, together with practical examples.
          </p>
          <ul>
            A. To administer your account and provide our services to you
            <li>• Create and manage your account</li>
            <li>
              • Provide you with customer support and respond to your requests
            </li>
            <li>• Complete your transactions</li>
            <li>
              • Communicate with you about our services, including order
              management and billing
            </li>
          </ul>

          <ul>
            B. To help you connect with other users
            <li>
              • Analyze your profile, activity on the service, and preferences
              to recommend meaningful connections to you and recommend you to
              others; For more information on our profiling and automated
              decision-making, please see our FAQ
            </li>
            <li>• Show users’ profiles to one another</li>
          </ul>

          <ul>
            C. To ensure a consistent experience across your devices
            <li>
              Link the various devices you use so that you can enjoy a
              consistent experience of our services on all of them. We do this
              by linking devices and browser data, such as when you log into
              your account on different devices or by using partial or full IP
              address, browser version and similar data about your devices to
              help identify and link them.
            </li>
          </ul>

          <ul>
            D. To provide new iBeor services to you
            <li>
              • Register you and display your profile on new iBeor features and
              apps
            </li>
            <li>• Administer your account on these new features and apps</li>
          </ul>
          <ul>
            E. To serve you relevant offers and ads
            <li>
              • Administer sweepstakes, contests, discounts or other offers
            </li>
            <li>
              • Develop, display and track content and advertising tailored to
              your interests on our services and other sites
            </li>
            <li>
              • Communicate with you by email, phone, social media or mobile
              device about products or services that we think may interest you
            </li>
          </ul>
          <ul>
            F. To improve our services and develop new ones
            <li>• Administer focus groups and surveys</li>
            <li>
              • Conduct research and analysis of users’ behavior to improve our
              services and content (for instance, we may decide to change the
              look and feel or even substantially modify a given feature based
              on users’ behavior)
            </li>
            <li>
              • Develop new features and services (for example, we may decide to
              build a new interests-based feature further to requests received
              from users).
            </li>
          </ul>

          <ul>
            G. To prevent, detect and fight fraud or other illegal or
            unauthorized activities
            <li>
              • Address ongoing or alleged misbehavior on and off-platform
            </li>
            <li>
              • Perform data analysis to better understand and design
              countermeasures against these activities
            </li>
            <li>
              • Retain data related to fraudulent activities to prevent against
              recurrences
            </li>
          </ul>

          <ul>
            H. To ensure legal compliance
            <li>• Comply with legal requirements</li>
            <li>• Assist law enforcement</li>
            <li>• Enforce or exercise our rights, for example our Terms</li>
          </ul>

          <span>
            I. To process your information as described above, we rely on the
            following legal bases:
          </span>

          <ul>
            <li>
              • Provide our service to you: Most of the time, the reason we
              process your information is to perform the contract that you have
              with us. For instance, as you go about using our service to build
              meaningful connections, we use your information to maintain your
              account and your profile, to make it viewable to other users and
              recommend other users to you.
            </li>
            <li>
              • Legitimate interests: We may use your information where we have
              legitimate interests to do so. For instance, we analyze users’
              behavior on our services to continuously improve our offerings, we
              suggest offers we think might interest you, and we process
              information for administrative, fraud detection and other legal
              purposes.
            </li>
            <li>
              • Consent: From time to time, we may ask for your consent to use
              your information for certain specific reasons. You may withdraw
              your consent at any time by contacting us at the address provided
              at the end of this Privacy Policy.
            </li>
          </ul>

          <span>6. How We Share Information</span>
          <p>
            Since our goal is to help you make meaningful connections, the main
            sharing of users’ information is, of course, with other users. We
            also share some users’ information with service providers and
            partners who assist us in operating the services, with other Match
            Group companies and, in some cases, legal authorities. Read on for
            more details about how your information is shared with others.
          </p>

          <li style={{ color: "#000" }}>
            <b>• With other users</b>
          </li>
          <p>
            You share information with other users when you voluntarily disclose
            information on the service (including your public profile). Please
            be careful with your information and make sure that the content you
            share is stuff that you’re comfortable being publicly viewable since
            neither you nor we can control what others do with your information
            once you share it. If you choose to limit the audience for all or
            part of your profile or for certain content or information about
            you, then it will be visible according to your settings.
          </p>
          <li style={{ color: "#000" }}>
            <b>• With our service providers and partners</b>
          </li>
          <p>
            We use third parties to help us operate and improve our services.
            These third parties assist us with various tasks, including data
            hosting and maintenance, analytics, customer care, marketing,
            advertising, payment processing and security operations. We may also
            share information with partners who distribute and assist us in
            advertising our services. For instance, we may share limited
            information on you in hashed, non-human readable form to advertising
            partners. We follow a strict vetting process prior to engaging any
            service provider or working with any partner. All of our service
            providers and partners must agree to strict confidentiality
            obligations.
          </p>
          <li style={{ color: "#000" }}>
            <b>• For corporate transactions</b>
          </li>

          <p>
            We may transfer your information if we are involved, whether in
            whole or in part, in a merger, sale, acquisition, divestiture,
            restructuring, reorganization, dissolution, bankruptcy or other
            change of ownership or control.
          </p>

          <span>• When required by law</span>

          <p>
            We may disclose your information if reasonably necessary: (i) to
            comply with a legal process, such as a court order, subpoena or
            search warrant, government / law enforcement investigation or other
            legal requirements; (ii) to assist in the prevention or detection of
            crime (subject in each case to applicable law); or (iii) to protect
            the safety of any person.
          </p>
          <span>• To enforce legal rights</span>
          <p>
            We may also share information: (i) if disclosure would mitigate our
            liability in an actual or threatened lawsuit; (ii) as necessary to
            protect our legal rights and legal rights of our users, business
            partners or other interested parties; (iii) to enforce our
            agreements with you; and (iv) to investigate, prevent, or take other
            action regarding illegal activity, suspected fraud or other
            wrongdoing.
          </p>
          <span>• With your consent or at your request</span>
          <p>
            We may ask for your consent to share your information with third
            parties. In any such case, we will make it clear why we want to
            share the information. We may use and share non-personal information
            (meaning information that, by itself, does not identify who you are
            such as device information, general demographics, general behavioral
            data, geolocation in de-identified form), as well as personal
            information in hashed, non-human readable form, under any of the
            above circumstances. We may also share this information with other
            companies and third parties (notably advertisers) to develop and
            deliver targeted advertising on our services and on websites or
            applications of third parties, and to analyze and report on
            advertising you see. We may combine this information with additional
            non-personal information or personal information in hashed,
            non-human readable form collected from other sources. More
            information on our use of cookies and similar technologies can be
            found in our Cookie Policy
          </p>

          <span>7. Cross-Border Data Transfers</span>
          <p>
            Sharing of information laid out in Section 6 sometimes involves
            cross-border data transfers, for instance to the United States of
            America and other jurisdictions. As an example, where the service
            allows for users to be located in the European Economic Area
            (“EEA”), their personal information is transferred to countries
            outside of the EEA. We use standard contract clauses approved by the
            European Commission or other suitable safeguard to permit data
            transfers from the EEA to other countries. Standard contractual
            clauses are commitments between companies transferring personal
            data, binding them to protect the privacy and security of your data
          </p>

          <span>8. Your Rights</span>
          <ul style={{ marginBottom: "0px" }}>
            <p>
              We want you to be in control of your information, so we have
              provided you with the following tools:
            </p>
            <li style={{ lineHeight: "1.8" }}>
              Access / Update tools in the service. Tools and account settings
              that help you to access, rectify or delete information that you
              provided to us and that’s associated with your account directly
              within the service. If you have any question on those tools and
              settings, please contact our customer care team for help here.
            </li>
            <li style={{ lineHeight: "1.8" }}>
              Device permissions. Mobile platforms have permission systems for
              specific types of device data and notifications, such as phone
              book and location services as well as push notifications. You can
              change your settings on your device to either consent or oppose
              the collection of the corresponding information or the display of
              the corresponding notifications. Of course, if you do that,
              certain services may lose full functionality.
            </li>
            <li>
              Deletion. You can delete your account by using the corresponding
              functionality directly on the service.
            </li>
            <ul>
              <p>
                We want you to be aware of your privacy rights. Here are a few
                key points to remember:
              </p>
              <li style={{ lineHeight: "1.8" }}>
                Reviewing your information. Applicable privacy laws may give you
                the right to review the personal information we keep about you
                (depending on the jurisdiction, this may be called right of
                access, right of portability or variations of those terms). You
                can request a copy of your personal information by putting in
                such a request here.
              </li>
              <li style={{ lineHeight: "1.8" }}>
                Updating your information. If you believe that the information
                we hold about you is inaccurate or that we are no longer
                entitled to use it and want to request its rectification,
                deletion or object to its processing, please contact us here.
              </li>
            </ul>
          </ul>
          <p style={{ marginTop: "0px", paddingTop: "0px", lineHeight: "1.8" }}>
            For your protection and the protection of all of our users, we may
            ask you to provide proof of identity before we can answer the above
            requests. Keep in mind, we may reject requests for certain reasons,
            including if the request is unlawful or if it may infringe on trade
            secrets or intellectual property or the privacy of another user. If
            you wish to receive information relating to another user, such as a
            copy of any messages you received from him or her through our
            service, the other user will have to contact our Privacy Officer to
            provide their written consent before the information is released.
            Also, we may not be able to accommodate certain requests to object
            to the processing of personal information, notably where such
            requests would not allow us to provide our service to you anymore.
            For instance, we cannot provide our service if we do not have your
            date of birth.
          </p>
          <li style={{ lineHeight: "1.8" }}>
            Uninstall. You can stop all information collection by an app by
            uninstalling it using the standard uninstall process for your
            device. If you uninstall the app from your mobile device, the unique
            identifier associated with your device will continue to be stored.
            If you re-install the application on the same mobile device, we will
            be able to re-associate this identifier to your previous
            transactions and activities.
          </li>
          <li style={{ lineHeight: "1.8" }}>
            Accountability. In certain countries, including in the European
            Union, you have a right to lodge a complaint with the appropriate
            data protection authority if you have concerns about how we process
            your personal information. The data protection authority you can
            lodge a complaint with notably may be that of your habitual
            residence, where you work or where we are established.
          </li>
          <span>9. Residents of California</span>
          <p>
            If you are a California resident, you can request a notice
            disclosing the categories of personal information about you that we
            have shared with third parties for their direct marketing purposes
            during the preceding calendar year. To request this notice, please
            submit your request here. Please allow 30 days for a response. For
            your protection and the protection of all of our users, we may ask
            you to provide proof of identity before we can answer such a
            request.
          </p>

          <span>10. How We Protect Your Information</span>

          <p>
            We work hard to protect you from unauthorized access to or
            alteration, disclosure or destruction of your personal information.
            As with all technology companies, although we take steps to secure
            your information, we do not promise, and you should not expect, that
            your personal information will always remain secure. We regularly
            monitor our systems for possible vulnerabilities and attacks and
            regularly review our information collection, storage and processing
            practices to update our physical, technical and organizational
            security measures. We may suspend your use of all or part of the
            services without notice if we suspect or detect any breach of
            security. If you believe that your account or information is no
            longer secure, please notify us immediately here In order to ensure
            that our systems and your information are protected against
            unauthorized access, theft and loss, we implemented a bug bounty
            program. For more information about our bug bounty program, please
            click here.
          </p>

          <span>11. How Long We Retain Your Information</span>
          <p>
            We keep your personal information only as long as we need it for
            legitimate business purposes (as laid out in Section 5) and as
            permitted by applicable law. To protect the safety and security of
            our users on and off our services, we implement a safety retention
            window of three months following account deletion. During this
            period, account information will be retained although the account
            will of course not be visible on the services anymore. In practice,
            we delete or anonymize your information upon deletion of your
            account (following the safety retention window) or after two years
            of continuous inactivity, unless:
          </p>

          <p>
            1. we must keep it to comply with applicable law (for instance, some
            “traffic data” is kept for one year to comply with statutory data
            retention obligations);
          </p>
          <p>
            2. we must keep it to evidence our compliance with applicable law
            (for instance, records of consents to our Terms, Privacy Policy and
            other similar consents are kept for five years);
          </p>
          <p>
            3. there is an outstanding issue, claim or dispute requiring us to
            keep the relevant information until it is resolved; or
          </p>
          <p>
            4. The information must be kept for our legitimate business
            interests, such as fraud prevention and enhancing users' safety and
            security. For example, information may need to be kept to prevent a
            user who was banned for unsafe behavior or security incidents from
            opening a new account.
          </p>
          <p>
            Keep in mind that even though our systems are designed to carry out
            data deletion processes according to the above guidelines, we cannot
            promise that all data will be deleted within a specific timeframe
            due to technical constraints.
          </p>

          <span>12. Children's Privacy</span>
          <p>
            Our services are restricted to users who are 18 years of age or
            older. We do not permit users under the age of 18 on our platform
            and we do not knowingly collect personal information from anyone
            under the age of 18. If you suspect that a user is under the age of
            18, please use the reporting mechanism available through the
            service.
          </p>

          <span>13. Privacy Policy Changes</span>
          <p>
            Because we’re always looking for new and innovative ways to help you
            build meaningful connections, this policy may change over time. We
            will notify you before any material changes take effect so that you
            have time to review the changes.
          </p>

          <p>
            By post:
            <br />
            Privacy Officer
            <br />
            iBeor, Inc
            <br />
            5429 Lyndon B Johnson Fwy
            <br />
            Dallas, TX 75240, USA
            <br />
          </p>
        </FooterItems>
      </FooterContainer>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}
