import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import { FooterContainer, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";

export default function TermCondition() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={newibeorlogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>
        <Terms>
          <heading>iBeor Terms of Use Agreement</heading>
          <p>
            Welcome to iBeor, operated by iBeor, Inc (“us,” “we,” the “Company”
            or “iBeor”).<br></br> Notice to California subscribers: You may
            cancel your subscription, without penalty or obligation, at any time
            prior to midnight of the third business day following the date you
            subscribed. If you subscribed using your Apple ID, refunds are
            handled by Apple, not iBeor. If you wish to request a refund, please
            visit{" "}
            <a href="https://getsupport.apple.com">
              https://getsupport.apple.com
            </a>
            . If you subscribed using your Google Play Store account or through
            iBeor Online, contact customer support
          </p>
          <h4>1. Acceptance of Terms of Use Agreement.</h4>
          <p>
            By creating a iBeor account or by using any iBeor service, whether
            through a mobile device, mobile application or computer
            (collectively, the “Service”) you agree to be bound by (i) these
            Terms of Use, (ii) our Privacy Policy, Cookie Policy, Arbitration
            Procedures, Safety Tips, and Community Guidelines, each of which is
            incorporated by reference into this Agreement, and (iii) any terms
            disclosed to you if you purchase or have purchased additional
            features, products or services we offer on the Service
            (collectively, this “Agreement”). If you do not accept and agree to
            be bound by all of the terms of this Agreement (other than the
            limited one-time opt-out right for certain members provided for in
            Section 15), you should not use the Service. We may make changes to
            this Agreement and to the Service from time to time. We may do this
            for a variety of reasons including to reflect changes in or
            requirements of the law, new features, or changes in business
            practices. The most recent version of this Agreement will be posted
            on the Service under Settings and also on ibeor-app.com, and you
            should regularly check for the most recent version. The most recent
            version is the version that applies. If the changes include material
            changes to your rights or obligations, we will notify you in advance
            of the changes (unless we’re unable to do so under applicable law)
            by reasonable means, which could include notification through the
            Service or via email. If you continue to use the Service after the
            changes become effective, then you agree to the revised Agreement.
            You agree that this Agreement shall supersede any prior agreements
            (except as specifically stated herein), and shall govern your entire
            relationship with iBeor, including but not limited to events,
            agreements, and conduct preceding your acceptance of this Agreement.
          </p>
          <h4>2. Eligibility</h4>

          <p style={{ margin: 0, marginTop: "20px" }}>
            {/* <strong> */}
            <b>
              You must be at least 18 years of age to create an account on iBeor
              and use the Service. By creating an account and using the Service,
              you represent and warrant that:
            </b>
            {/* </strong> */}
          </p>
          <ul className="ul-style">
            <li>1. You can form a binding contract with iBeor,</li>
            <li>
              2. You are not a person who is barred from using the Service under
              the laws of the United States or any other applicable jurisdiction
              (for example, you do not appear on the U.S. Treasury Department’s
              list of Specially Designated Nationals or face any other similar
              prohibition),
            </li>
            <li>
              3. You will comply with this Agreement and all applicable local,
              state, national and international laws, rules and regulations, and
            </li>
            <li>
              4. You have never been convicted of or pled no contest to a
              felony, a sex crime, or any crime involving violence, and that you
              are not required to register as a sex offender with any state,
              federal or local sex offender registry.
            </li>
          </ul>
          <h4>3. Your Account</h4>
          <p>
            In order to use iBeor, you may sign in using a number of ways,
            including by telephone number, Apple login, or Facebook login. If
            you choose to use your Facebook login, you authorize us to access
            and use certain Facebook account information, including but not
            limited to your public Facebook profile. For more information
            regarding the information we collect from you and how we use it,
            please consult our Privacy Policy.
            <br />
            You are responsible for maintaining the confidentiality of your
            login credentials you use to sign up for iBeor, and you are solely
            responsible for all activities that occur under those credentials.
            If you think someone has gained access to your account, please
            immediately contact us.
          </p>
          <h4>4. Modifying the Service and Termination.</h4>
          <p>
            iBeor is always striving to improve the Service and bring you
            additional functionality that you will find engaging and useful.
            This means we may add new product features or enhancements from time
            to time as well as remove some features, and if these actions do not
            materially affect your rights or obligations, we may not provide you
            with notice before taking them. We may even suspend the Service
            entirely, in which event we will notify you in advance unless
            extenuating circumstances, such as safety or security concerns,
            prevent us from doing so.
            <br />
            You may terminate your account at any time, for any reason, by
            following the instructions in “Settings” in the Service. However, if
            you use a third party payment account, you will need to manage in
            app purchases through such account (e.g., iTunes, Google Play) to
            avoid additional billing. iBeor may terminate your account at any
            time without notice if it believes that you have violated this
            Agreement. Upon such termination, you will not be entitled to any
            refund for purchases. After your account is terminated, this
            Agreement will terminate, except that the following provisions will
            still apply to you and iBeor: Section 4, Section 5, and Sections 12
            through 19.
          </p>

          <h4>5. Safety; Your Interactions with Other Members.</h4>
          <p>
            Though iBeor strives to encourage a respectful member experience
            through features like the double opt-in that allows members to
            communicate only after they have both indicated interest in one
            another, iBeor is not responsible for the conduct of any member on
            or off of the Service. You agree to use caution in all interactions
            with other members, particularly if you decide to communicate off
            the Service or meet in person. In addition, you agree to review and
            follow iBeor’s Safety Tips prior to using the Service. You agree
            that you will not provide your financial information (for example,
            your credit card or bank account information), or wire or otherwise
            send money to other members.
            <br />
            YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS.
            YOU UNDERSTAND THAT IBEOR DOES NOT CONDUCT CRIMINAL BACKGROUND
            CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF
            ITS MEMBERS. IBEOR MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE
            CONDUCT OR COMPATIBILITY OF MEMBERS. IBEOR RESERVES THE RIGHT TO
            CONDUCT – AND YOU AUTHORIZE IBEOR TO CONDUCT – ANY CRIMINAL
            BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER
            SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS OBTAINED BY IT
            OR WITH THE ASSISTANCE OF A CONSUMER REPORTING AGENCY, AND YOU AGREE
            THAT ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT PURPOSE.
          </p>
          <h4>6. Rights iBeor Grants You.</h4>

          <p>
            iBeor grants you a personal, worldwide, royalty-free,
            non-assignable, nonexclusive, revocable, and non-sublicensable
            license to access and use the Service. This license is for the sole
            purpose of letting you use and enjoy the Service’s benefits as
            intended by iBeor and permitted by this Agreement. Therefore, you
            agree not to:
          </p>
          <ul className="ul-style">
            <li>
              • Use the Service or any content contained in the Service for any
              commercial purposes without our written consent.
            </li>
            <li>
              • Copy, modify, transmit, create any derivative works from, make
              use of, or reproduce in any way any copyrighted material, images,
              trademarks, trade names, service marks, or other intellectual
              property, content or proprietary information accessible through
              the Service without iBeor’s prior written consent.
            </li>
            <li>
              • Express or imply that any statements you make are endorsed by
              iBeor.
            </li>
            <li>
              • Use any robot, bot, spider, crawler, scraper, site
              search/retrieval application, proxy or other manual or automatic
              device, method or process to access, retrieve, index, “data mine,”
              or in any way reproduce or circumvent the navigational structure
              or presentation of the Service or its contents.
            </li>
            <li>
              • Use the Service in any way that could interfere with, disrupt or
              negatively affect the Service or the servers or networks connected
              to the Service.
            </li>
            <li>
              • Forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any information transmitted to or through
              the Service.
            </li>
            <li>
              • “frame” or “mirror” any part of the Service without iBeor’s
              prior written authorization.
            </li>
            <li>
              • Use meta tags or code or other devices containing any reference
              to iBeor or the Service (or any trademark, trade name, service
              mark, logo or slogan of iBeor) to direct any person to any other
              website for any purpose.
            </li>
            <li>
              • Modify, adapt, sublicense, translate, sell, reverse engineer,
              decipher, decompile or otherwise disassemble any portion of the
              Service, or cause others to do so.
            </li>
            <li>
              • Use or develop any third-party applications that interact with
              the Service or other members’ Content or information without our
              written consent.
            </li>
            <li>
              • Encourage or promote any activity that violates this Agreement.
            </li>
            <li>
              • Use access, or publish the iBeor application programming
              interface without our written consent.
            </li>
            <li>
              • Probe, scan or test the vulnerability of our Service or any
              system or network.
            </li>
          </ul>
          <p>
            iBeor may investigate and take any available legal action in
            response to illegal and/ or unauthorized uses of the Service,
            including termination of your account. Any software that we provide
            you may automatically download and install upgrades, updates, or
            other new features. You may be able to adjust these automatic
            downloads through your device’s settings.
          </p>
          <h4>7. Rights you Grant iBeor.</h4>
          <p>
            By creating an account, you grant to IBeor a worldwide,
            transferable, sub-licensable, royalty-free, right and license to
            host, store, use, copy, display, reproduce, adapt, edit, publish,
            modify and distribute information you authorize us to access from
            third parties such as Facebook, Google, or Apple, as well as any
            information you post, upload, display or otherwise make available
            (collectively, “post”) on the Service or transmit to other members
            (collectively, “Content”). IBeor’s license to your Content shall be
            non-exclusive, except that IBeor’s license shall be exclusive with
            respect to derivative works created through use of the Service. For
            example, IBeor would have an exclusive license to screenshots of the
            Service that include your Content.
          </p>
          <p>
            In addition, so that IBeor can prevent the use of your Content
            outside of the Service, you authorize IBeor to act on your behalf
            with respect to infringing uses of your Content taken from the
            Service by other members or third parties. This expressly includes
            the authority, but not the obligation, to send notices pursuant to
            17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf
            if your Content is taken and used by third parties outside of the
            Service. Our license to your Content is subject to your rights under
            applicable law (for example laws regarding personal data protection
            to the extent any Content contains personal information as defined
            by those laws) and is for the limited purpose of operating,
            developing, providing, and improving the Service and researching and
            developing new ones.
          </p>
          <p>
            You agree that any Content you place or that you authorize us to
            place on the Service may be viewed by other members and may be
            viewed by any person visiting or participating in the Service (such
            as individuals who may receive shared Content from other IBeor
            members). You agree that all information that you submit upon
            creation of your account, including information submitted from your
            Facebook account, is accurate and truthful and you have the right to
            post the Content on the Service and grant the license to IBeor
            above. You understand and agree that we may monitor or review any
            Content you post as part of a Service. We may delete any Content, in
            whole or in part, that in our sole judgment violates this Agreement
            or may harm the reputation of the Service. When communicating with
            our customer care representatives, you agree to be respectful and
            kind. If we feel that your behavior towards any of our customer care
            representatives or other employees is at any time threatening,
            harassing, or offensive, we reserve the right to immediately
            terminate your account.
          </p>
          <p>
            {" "}
            In consideration for IBeor allowing you to use the Service, you
            agree that we, our affiliates, and our third-party partners may
            place advertising on the Service. By submitting suggestions or
            feedback to IBeor regarding our Service, you agree that IBeor may
            use and share such feedback for any purpose without compensating
            you. You agree that IBeor may access, preserve and disclose your
            account information and Content if required to do so by law or in a
            good faith belief that such access, preservation or disclosure is
            reasonably necessary, such as to: (i) comply with legal process;
            (ii) enforce this Agreement; (iii) respond to claims that any
            Content violates the rights of third parties; (iv) respond to your
            requests for customer service; or (v) protect the rights, property
            or personal safety of the Company or any other person.
          </p>
          <h4>8. Community Rules.</h4>
          <p style={{ margin: 0, marginTop: "20px" }}>
            <b>By using the Service, you agree that you will not:</b>
          </p>
          <ul className="ul-style">
            <li>
              • Use the Service for any purpose that is illegal or prohibited by
              this Agreement.
            </li>
            <li>• Use the Service for any harmful or nefarious purpose.</li>
            <li>• Use the Service in order to damage IBeor.</li>
            <li>
              • Violate our Community Guidelines, as updated from time to time.
            </li>
            <li>• Spam, solicit money from or defraud any members.</li>
            <li>
              • Impersonate any person or entity or post any images of another
              person without his or her permission.
            </li>
            <li>
              • Bully, “stalk,” intimidate, assault, harass, mistreat or defame
              any person.
            </li>
            <li>
              • Post any Content that violates or infringes anyone’s rights,
              including rights of publicity, privacy, copyright, trademark or
              other intellectual property or contract right.
            </li>
            <li>
              • Post any Content that is hate speech, threatening, sexually
              explicit or pornographic; incites violence; or contains nudity or
              graphic or gratuitous violence.
            </li>
            <li>
              • Post any Content that promotes racism, bigotry, hatred or
              physical harm of any kind against any group or individual.
            </li>
            <li>
              • Solicit passwords for any purpose, or personal identifying
              information for commercial or unlawful purposes from other users
              or disseminate another person’s personal information without his
              or her permission.
            </li>
            <li>
              • Use another user’s account, share an account with another user,
              or maintain more than one account.
            </li>
            <li>
              • Create another account if we have already terminated your
              account, unless you have our permission.
            </li>
            <li>
              • IBeor reserves the right to investigate and/or terminate your
              account without a refund of any purchases if you have violated
              this Agreement, misused the Service or behaved in a way that IBeor
              regards as inappropriate or unlawful, including actions or
              communications that occur on or off the Service.
            </li>
          </ul>
          <h4>9. Other Members’ Content.</h4>
          <p>
            Although IBeor reserves the right to review and remove Content that
            violates this Agreement, such Content is the sole responsibility of
            the member who posts it, and IBeor cannot guarantee that all Content
            will comply with this Agreement. If you see Content on the Service
            that violates this Agreement, please report it within the Service or
            via our contact form.
          </p>
          <h4>10. Purchases.</h4>
          <p>
            Generally. From time to time, IBeor may offer products and services
            for purchase (“in app purchases”) through the App Store, Google Play
            Store, carrier billing, IBeor direct billing or other payment
            platforms authorized by IBeor. If you choose to make an in app
            purchase, you will be prompted to confirm your purchase with the
            applicable payment provider, and your method of payment (be it your
            card or a third party account such as Google Play Store or the App
            Store) (your “Payment Method”) will be charged at the prices
            displayed to you for the service(s) you’ve selected as well as any
            sales or similar taxes that may be imposed on your payments, and you
            authorize IBeor or the third party account, as applicable, to charge
            you. Auto-Renewal. If you purchase an auto-recurring periodic
            subscription, your Payment Method will continue to be billed for the
            subscription until you cancel. After your initial subscription
            commitment period, and again after any subsequent subscription
            period, your subscription will automatically continue for an
            additional equivalent period, at the price you agreed to when
            subscribing. If you do not wish your subscription to renew
            automatically, or if you want to change or terminate your
            subscription, you will need to access your third party account (or
            Account Settings on IBeor, if applicable) and follow the
            instructions to cancel your subscription, even if you have otherwise
            deleted your account with us or deleted the IBeor application from
            your device. Deleting your account on IBeor or deleting the IBeor
            application from your device does not cancel your subscription;
            IBeor will retain all funds charged to your Payment Method until you
            cancel your subscription on IBeor or the third party account, as
            applicable. If you cancel your subscription, you may use your
            subscription until the end of your then-current subscription term,
            and your subscription will not be renewed after your then-current
            term expires.
            <br />
            Additional Terms that apply if you pay IBeor directly with your
            Payment Method. If you pay IBeor directly, IBeor may correct any
            billing errors or mistakes that it makes even if it has already
            requested or received payment. If you initiate a chargeback or
            otherwise reverse a payment made with your Payment Method, IBeor may
            terminate your account immediately in its sole discretion. You may
            edit your Payment Method information by visiting IBeor and going to
            Settings. If a payment is not successfully settled, due to
            expiration, insufficient funds, or otherwise, and you do not edit
            your Payment Method information or cancel your subscription, you
            remain responsible for any uncollected amounts and authorize us to
            continue billing the Payment Method, as it may be updated. This may
            result in a change to your payment billing dates. In addition, you
            authorize us to obtain updated or replacement expiration dates and
            card numbers for your credit or debit card as provided by your
            credit or debit card issuer. The terms of your payment will be based
            on your Payment Method and may be determined by agreements between
            you and the financial institution, credit card issuer or other
            provider of your chosen Payment Method. If you reside outside of the
            Americas, you agree that your payment to IBeor will be through MTCH
            Technology Services Limited.
            <br />
            Virtual Items. From time to time, you may be able to purchase a
            limited, personal, non-transferable, non-sublicensable, revocable
            license to use “virtual items,” which could include virtual products
            or virtual “coins” or other units that are exchangeable within the
            Service for virtual products (collectively, “Virtual Items”). Any
            Virtual Item balance shown in your account does not constitute a
            real-world balance or reflect any stored value, but instead
            constitutes a measurement of the extent of your license. Virtual
            Items do not incur fees for non-use, however, the license granted to
            you in Virtual Items will terminate in accordance with the terms of
            this Agreement, when IBeor ceases providing the Service, or your
            account is otherwise closed or terminated. IBeor, in its sole
            discretion, reserves the right to charge fees for the right to
            access or use Virtual Items and may distribute Virtual Items with or
            without charge. IBeor may manage, regulate, control, modify or
            eliminate Virtual Items at any time. IBeor shall have no liability
            to you or any third party in the event that IBeor exercises any such
            rights. Virtual Items may only be redeemed through the Service. ALL
            PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE
            ARE FINAL AND NON-REFUNDABLE. The provision of Virtual Items for use
            in the Service is a service that commences immediately upon the
            acceptance of your purchase of such Virtual Items. YOU ACKNOWLEDGE
            THAT IBEOR IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND
            THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED
            VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
            VOLUNTARY OR INVOLUNTARY.
            <br />
            Refunds. Generally, all charges for purchases are nonrefundable, and
            there are no refunds or credits for partially used periods. We may
            make an exception if a refund for a subscription offering is
            requested within fourteen days of the transaction date, or if the
            laws applicable in your jurisdiction provide for refunds. For
            subscribers residing in Arizona, California, Connecticut, Illinois,
            Iowa, Minnesota, New York, North Carolina, Ohio and Wisconsin, the
            terms below apply:
            <br />
            You may cancel your subscription, without penalty or obligation, at
            any time prior to midnight of the third business day following the
            date you subscribed. In the event that you die before the end of
            your subscription period, your estate shall be entitled to a refund
            of that portion of any payment you had made for your subscription
            which is allocable to the period after your death. In the event that
            you become disabled (such that you are unable to use the services of
            IBeor) before the end of your subscription period, you shall be
            entitled to a refund of that portion of any payment you had made for
            your subscription which is allocable to the period after your
            disability by providing the company notice in the same manner as you
            request a refund as described below.
            <br />
            <b>To request a refund:</b>
            <br />
            If you subscribed using your Apple ID, refunds are handled by Apple,
            not IBeor. To request a refund, go to the App Store, click on your
            Apple ID, select “Purchase history,” find the transaction and hit
            “Report Problem”. You can also submit a request at
            https://getsupport.apple.com. If you subscribed using your Google
            Play Store account or through IBeor directly: please contact
            customer support with your order number for the Google Play Store
            (you can find the order number in the order confirmation email or by
            logging in to Google Wallet) or IBeor (you can find this on your
            confirmation email). You may also mail or deliver a signed and dated
            notice which states that you, the buyer, are canceling this
            Agreement, or words of similar effect. Please also include the email
            address or mobile number associated with your account along with
            your order number. This notice shall be sent to: IBeor, Attn:
            Cancellations, P.O. Box 25472, Dallas, Texas 75225, USA (in
            addition, Ohio members may send a facsimile to 214-853-4309).
            Pricing IBeor operates a global business, and our pricing varies by
            a number of factors. We frequently offer promotional rates - which
            can vary based on region, length of subscription, bundle size and
            more. We also regularly test new features and payment options.
          </p>
          <h4>
            11. Notice and Procedure for Making Claims of Copyright
            Infringement.
          </h4>
          <p style={{ margin: 0, marginTop: "20px" }}>
            <b>
              If you believe that your work has been copied and posted on the
              Service in a way that constitutes copyright infringement, please
              submit a takedown request using the form here. If you contact us
              regarding alleged copyright infringement, please be sure to
              include the following information:
            </b>
          </p>
          <ul className="ul-style">
            <li>
              • An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest;
            </li>
            <li>
              • A description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              • A description of where the material that you claim is infringing
              is located on the Service (and such description must be reasonably
              sufficient to enable us to find the alleged infringing material);
            </li>
            <li>
              • Your contact information, including address, telephone number
              and email address, and the copyright owner’s identity;
            </li>
            <li>
              • A written statement by you that you have a good faith belief
              that the disputed use is not authorized by the copyright owner,
              its agent, or the law; and
            </li>
            <li>
              • A statement by you, made under penalty of perjury, that the
              above information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </li>
            <li>• IBeor will terminate the accounts of repeat infringers.</li>
          </ul>
          <h4>12. Disclaimers.</h4>
          {/* style={{ fontFamily: "playfair display,sans-serif" }} */}
          <p>
            IBEOR PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS
            AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES
            OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH
            RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN),
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE OR NON-INFRINGEMENT. IBEOR DOES NOT REPRESENT OR WARRANT
            THAT (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE,
            (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C)
            THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE
            WILL BE ACCURATE.
            <br />
            IBEOR TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER
            MEMBER OR THIRD PARTY POSTS, SENDS OR RECEIVES THROUGH THE SERVICE.
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK.
            <br />
            IBEOR DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY CONDUCT OF YOU
            OR ANY OTHER MEMBER, ON OR OFF THE SERVICE.
          </p>
          <h4>13. Third Party Services.</h4>
          <p>
            The Service may contain advertisements and promotions offered by
            third parties and links to other web sites or resources. IBeor is
            not responsible for the availability (or lack of availability) of
            such external websites or resources. If you choose to interact with
            the third parties made available through our Service, such party’s
            terms will govern their relationship with you. IBeor is not
            responsible or liable for such third parties’ terms or actions.
          </p>
          <h4>14. Limitation of Liability.</h4>

          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            IBEOR, ITS AFFILIATES, EMPLOYEES, LICENSORS OR SERVICE PROVIDERS BE
            LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
            SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING, WITHOUT
            LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR
            INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
            LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICE; (II) THE CONDUCT OR CONTENT OF OTHER
            MEMBERS` OR THIRD PARTIES ON, THROUGH OR FOLLOWING USE OF THE
            SERVICE; OR (III) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
            CONTENT, EVEN IF IBEOR HAS BEEN ADVISED AT ANY TIME OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING THE FOREGOING, IN NO
            EVENT SHALL IBEOR’S AGGREGATE LIABILITY TO YOU FOR ANY AND ALL
            CLAIMS ARISING OUT OF OR RELATING TO THE SERVICE OR THIS AGREEMENT
            EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO IBEOR DURING THE
            TWENTY-FOUR (24) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE THAT
            YOU FIRST FILE A LAWSUIT, ARBITRATION OR ANY OTHER LEGAL PROCEEDING
            AGAINST IBEOR, WHETHER IN LAW OR IN EQUITY, IN ANY TRIBUNAL. THE
            DAMAGES LIMITATION SET FORTH IN THE IMMEDIATELY PRECEDING SENTENCE
            APPLIES (i) REGARDLESS OF THE GROUND UPON WHICH LIABILITY IS BASED
            (WHETHER DEFAULT, CONTRACT, TORT, STATUTE, OR OTHERWISE), (ii)
            IRRESPECTIVE OF THE TYPE OF BREACH OF OBLIGATIONS, AND (iii) WITH
            RESPECT TO ALL EVENTS, THE SERVICE, AND THIS AGREEMENT.
            <br />
            THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION 14
            SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH
            RESPECT TO THEIR ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW
            THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF
            THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
          </p>
          <h4>
            15. Retroactive and Prospective Arbitration, Class-Action Waiver,
            and Jury Waiver.
          </h4>

          <p>
            <b>Except where prohibited by applicable law:</b>
          </p>
          <ul className="ul-style">
            <li>
              1. The exclusive means of resolving any disulute or claim arising
              out of or relating to this Agreement (including any alleged breach
              thereof), or the Service, regardless of the date of accrual and
              including past, pending, and future claims, shall be BINDING
              ARBITRATION administered by JAMS under the JAMS Streamlined
              Arbitration Rules & Procedures, except as modified by our
              Arbitration Procedures. The one exception to the exclusivity of
              arbitration is that either party has the right to bring an
              individual claim against the other in a small claims court of
              competent jurisdiction, or, if filed in arbitration, the
              responding party may request that the dispute proceed in small
              claims court instead if the claim is within the jurisdiction of
              the small claims court. If the request to proceed in small claims
              court is made before an arbitrator has been appointed, the
              arbitration shall be administratively closed. If the request to
              proceed in small claims court is made after an arbitrator has been
              appointed, the arbitrator shall determine whether the dispute
              should remain in arbitration or instead be decided in small claims
              court. Such arbitration shall be conducted by written submissions
              only, unless either you or IBeor elect to invoke the right to an
              oral hearing before the Arbitrator. But whether you choose
              arbitration or small claims court, you agree that you will not
              under any circumstances commence, maintain, or participate in any
              class action, class arbitration, or other representative action or
              proceeding against IBeor.
            </li>
            <li>
              2. By accepting this Agreement, you agree to the Arbitration
              Agreement in this Section 15 (subject to the limited one-time
              right to opt out within thirty (30) days belonging to members who
              first created an account or used the Service prior to May 9, 2018
              (such members, “Legacy Members"), discussed below). In doing so,
              BOTH YOU AND IBEOR GIVE UP THE RIGHT TO GO TO COURT to assert or
              defend any claims between you and IBeor (except for matters that
              may be properly taken to a small claims court and are within such
              court’s jurisdiction). YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE
              IN A CLASS ACTION OR OTHER CLASS PROCEEDING, including, without
              limitation, any past, pending or future class actions, including
              those existing as of the date of this Agreement, which include
              without limitation: Allan Candelore v. IBeor, Inc., Los Angeles
              Superior Court, State of California, County of Los Angeles, Case
              No. BC583162 (generally claiming, on behalf of California citizens
              who subscribed to IBeor Plus when they were at least 30 years old
              that IBeor violated the California Unruh Civil Rights Act by
              charging older subscribers a higher price than IBeor charged
              subscribers younger than 30) and Kim v. IBeor, Inc., United States
              District Court, Central District of California, Case No.
              2:18-cv-03093 generally claiming, on behalf of California citizens
              who subscribed to IBeor Plus when they were at least 30 years old
              that IBeor violated the California Unruh Civil Rights Act by
              charging older subscribers a higher price than IBeor charged
              subscribers younger than 30). This list may not be exhaustive, and
              there may be other currently pending actions against IBeor.
            </li>

            <li>
              3. If you assert a claim against IBeor outside of small claims
              court (and IBeor does not request that the claim be moved to small
              claims court), your rights will be determined by a NEUTRAL
              ARBITRATOR, NOT A JUDGE OR JURY, and the arbitrator shall
              determine all claims and all issues regarding the arbitrability of
              the dispute. The same is true for IBeor. Both you and IBeor are
              entitled to a fair hearing before the arbitrator. The arbitrator
              can generally grant the relief that a court can, including the
              ability to hear a dispositive motion (which may include a
              dispositive motion based upon the parties’ pleadings, as well as a
              dispositive motion based upon the parties’ pleadings along with
              the evidence submitted), but you should note that arbitration
              proceedings are usually simpler and more streamlined than trials
              and other judicial proceedings. Decisions by the arbitrator are
              enforceable in court and may be overturned by a court only for
              very limited reasons. For details on the arbitration process, see
              our Arbitration Procedures.
            </li>

            <li>
              4. The Jurisdiction and Venue provisions in Sections 16 and 17 are
              incorporated and are applicable to this Arbitration Agreement.
            </li>
          </ul>

          <p style={{ marginBottom: 0 }}>
            <b>
              As you decide whether to agree to this Arbitration Agreement, here
              are some important considerations:
            </b>
          </p>
          <ul className="ul-style">
            <li>
              • Arbitration is a process of private dispute resolution that does
              not involve the civil courts, a civil judge or a jury. Instead,
              the parties’ dispute is decided by a private arbitrator selected
              by the parties under the JAMS Streamlined Arbitration Rules &
              Procedures. Arbitration does not limit or affect the legal claims
              you as an individual may bring against IBeor. Agreeing to
              arbitration will only affect where those claims may be brought and
              how they will be resolved.
            </li>
            <li>
              • Arbitration is generally considered to be a more rapid dispute
              resolution process than the judicial system, but that is not
              always the case. The Arbitrator will typically determine whether
              IBeor or you will be required to pay or split the cost of any
              arbitration with IBeor, based on the circumstances presented.
            </li>
            <li>
              <b>IMPORTANT:</b> THERE ARE NOW, AND MAY BE IN THE FUTURE,
              LAWSUITS AGAINST IBEOR ALLEGING CLASS AND/OR REPRESENTATIVE CLAIMS
              ON YOUR BEHALF INCLUDING BUT NOT LIMITED TO CLASS ACTIONS
              DESCRIBED IN THIS SECTION 15, WHICH IF SUCCESSFUL, COULD
              POTENTIALLY RESULT IN SOME MONETARY OR OTHER RECOVERY TO YOU, IF
              YOU ELECT TO OPT OUT OF THE RETROACTIVE APPLICATION OF THIS
              ARBITRATION AGREEMENT. THE MERE EXISTENCE OF SUCH CLASS AND/OR
              REPRESENTATIVE LAWSUITS, HOWEVER, DOES NOT MEAN THAT SUCH LAWSUITS
              WILL ULTIMATELY SUCCEED, OR, EVEN IF SUCCESSFUL, THAT YOU WOULD BE
              ENTITLED TO ANY RECOVERY.
            </li>
            <li>
              You will be precluded from bringing any class or representative
              action against IBeor, unless you timely opt out of the retroactive
              application of this Arbitration Agreement, and you will also be
              precluded from participating in any recovery resulting from any
              class or representative action brought against IBeor, in each case
              provided you are not already bound by an arbitration agreement and
              class action waiver previously agreed to with IBeor.
            </li>
          </ul>
          <ul className="ul-style">
            <li>
              WHETHER TO AGREE TO THIS ARBITRATION AGREEMENT IS AN IMPORTANT
              DECISION. IT IS YOUR DECISION TO MAKE, AND YOU SHOULD TAKE CARE TO
              CONDUCT FURTHER RESEARCH AND TO CONSULT WITH OTHERS — INCLUDING
              BUT NOT LIMITED TO AN ATTORNEY — REGARDING THE CONSEQUENCES OF
              YOUR DECISION, JUST AS YOU WOULD WHEN MAKING ANY OTHER IMPORTANT
              BUSINESS OR LIFE DECISION. IF FOR ANY REASON THIS ARBITRATION
              AGREEMENT IS FOUND TO BE INVALID, YOU WILL NEVERTHELESS STILL BE
              BOUND BY ANY PRIOR VALID ARBITRATION AGREEMENT THAT YOU ENTERED
              INTO WITH IBEOR.
            </li>
            <li>
              Limited One-Time Right To Opt Out Of The Retroactive Application
              of the Arbitration Agreement
            </li>
            <li>
              <b>NOTE:</b> THIS OPT OUT SECTION DOES NOT (a) APPLY TO NEW
              MEMBERS AFTER MAY 8, 2018 (I.E., MEMBERS WHO HAD NEVER PREVIOUSLY
              USED IBEOR AND WHO FIRST CREATED AN ACCOUNT AFTER MAY 8, 2018) (b)
              EXEMPT ANY CLAIMS OR DISPUTES OF ANY MEMBER ARISING AFTER MAY 8,
              2018 FROM THE APPLICATION OF THE ARBITRATION AGREEMENT IN THIS
              SECTION 15, OR (c) APPLY TO ANY LEGACY MEMBER WHO LOGGED IN TO
              IBEOR OR USED THE SERVICE AFTER MAY 8, 2018, AND WHO DID NOT OR
              DOES NOT, AS APPLICABLE, EXERCISE SUCH MEMBER’S ONE-TIME OPT OUT
              RIGHT WITHIN 30 DAYS AFTER SUCH MEMBER’S FIRST LOGIN OR USE OF THE
              SERVICE AFTER SUCH DATE.
            </li>
            <li>
              SUBJECT TO THE FOREGOING PARAGRAPH, IF YOU ARE A LEGACY MEMBER
              (EXISTING MEMBER PRIOR TO MAY 9, 2018), AND YOUR OPT OUT RIGHT HAS
              NOT PREVIOUSLY EXPIRED, AND IF YOU DO NOT AGREE TO BE SUBJECT TO
              THIS ARBITRATION AGREEMENT ON A RETROACTIVE BASIS, YOU MUST OPT
              OUT OF THE RETROACTIVE APPLICATION OF THIS ARBITRATION AGREEMENT
              WITHIN 30 DAYS AFTER THE FIRST DATE ON WHICH YOU LOGIN TO IBEOR OR
              USE THE SERVICE AFTER MAY 8, 2018, IN THE FOLLOWING SPECIFIED
              MANNER:
            </li>
            <li>
              By sending an e-mail to support@ibeor-app.com within 30 days after
              the first date on which you login to IBeor or use the Service. The
              e-mail opt out must contain the following to be effective: your
              full name, address, email address and/ or phone number associated
              with your IBeor account, and a statement that you are opting out
              of the Retroactive Application of this Arbitration Agreement.
              Please do not direct any customer support inquiries to
              support@ibeor-app.com, as they will not be addressed; such
              inquiries should be directed to customer support.
            </li>
            <li>
              Should you not opt out of the retroactive application of this
              Arbitration Agreement within such 30 day period, you and IBeor
              shall be bound by the terms of this Arbitration Agreement,
              including its retroactive effect. You have the right to consult
              with counsel of your choice (at your cost) concerning this
              Arbitration Agreement. IF YOU OPT OUT OF THE RETROACTIVE EFFECT OF
              THIS ARBITRATION AGREEMENT, YOU WILL STILL BE SUBJECT TO AND BOUND
              BY ANY PRIOR ARBITRATION AGREEMENTS/ PROVISIONS YOU PREVIOUSLY
              AGREED TO WITH IBEOR AS WELL AS THIS ARBITRATION AGREEMENT ON A
              GOING FORWARD BASIS.
            </li>
          </ul>
         
          <h4>16. Governing Law.</h4>
          <p>
            Except where our arbitration agreement is prohibited by law, the
            laws of Texas, U.S.A., without regard to its conflict of laws rules,
            shall apply to any disputes arising out of or relating to this
            Agreement, the Service, or your relationship with IBeor.
            Notwithstanding the foregoing, the Arbitration Agreement in Section
            15 above shall be governed by the Federal Arbitration Act.
          </p>
          <h4>17. Venue.</h4>
          <p>
            Except for claims that may be properly brought in a small claims
            court of competent jurisdiction, all claims arising out of or
            relating to this Agreement, to the Service, or to your relationship
            with IBeor that for whatever reason are not submitted to arbitration
            will be litigated exclusively in the federal or state courts of
            Dallas County, Texas, U.S.A. You and IBeor consent to the exercise
            of personal jurisdiction of courts in the State of Texas and waive
            any claim that such courts constitute an inconvenient forum.
          </p>
          <h4>18. Indemnity by You.</h4>
          <p>
            You agree, to the extent permitted under applicable law, to
            indemnify, defend and hold harmless IBeor, our affiliates, and their
            and our respective officers, directors, agents, and employees from
            and against any and all complaints, demands, claims, damages,
            losses, costs, liabilities and expenses, including attorney’s fees,
            due to, arising out of, or relating in any way to your access to or
            use of the Service, your Content, or your breach of this Agreement.
          </p>
          <h4>19. Entire Agreement; Other.</h4>
          <p>
            This Agreement, which includes the Privacy Policy, Cookie Policy,
            Safety Tips, Community Guidelines, and Arbitration Procedures, and
            any terms disclosed to you if you purchase or have purchased
            additional features, products or services we offer on the Service,
            contains the entire agreement between you and IBeor regarding your
            relationship with IBeor and the use of the Service, with the
            following exception: anyone who opted out of the retroactive
            application of Section 15 is still subject to and bound by any prior
            agreements to arbitrate with IBeor as well as this agreement to
            arbitrate on a going forward basis. If any provision of this
            Agreement is held invalid, the remainder of this Agreement shall
            continue in full force and effect. The failure of IBeor to exercise
            or enforce any right or provision of this Agreement shall not
            constitute a waiver of such right or provision. You agree that your
            IBeor account is non-transferable and all of your rights to your
            account and its Content terminate upon your death. No agency,
            partnership, joint venture, fiduciary or other special relationship
            or employment is created as a result of this Agreement and you may
            not make any representations on behalf of or bind IBeor in any
            manner.
          </p>
        </Terms>
      </FooterContainer>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const Terms = styledComponents.div`

heading {
  font-family: "Nunito Sans",serif;
  font-style: normal;
  font-size:52px;
  font-weight: 400;
  color:#000;
  display: flex;
  justify-content: flex-start;
  margin:40px 0; 
}

p {

font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-size:17px;
  line-height: 1.8;
  font-weight: 400;
  color:#505965;
  margin:20px 0; 
}

h2 {
  font-family: "Nunito Sans",serif;
  font-style: normal;
  font-size:27px;
  line-height:42px;
  font-weight: 800;
  color:#000;
  margin:20px 0; 
}
ul{
font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-size:18px;
  line-height: 1.8;
  display: inline-block;
  font-weight: 400;
  color:#505965;
  margin:10px 0; 
  padding:0px;
}
li {
font-family: "Nunito Sans"
font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-size:18px;
  line-height: 1.4;
  display: inline-block;
  font-weight: 400;
  color:#505965;
  margin:10px 0; 

}



`;
