import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { FcApproval } from "react-icons/fc";

export default function ThankyouModal({ show, onHide, handleClick }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      {/* <Modal.Header closeButton style={{ border: "none" }}>
        <Modal.Title>Reset Phone Number</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <ThankyouWrapper>
          <FcApproval />
          <p>
            Thank you for your interest! <span>We will get back to you</span> as
            soon as possible!
          </p>
        </ThankyouWrapper>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button
          variant="primary"
          onClick={handleClick}
          style={ButtonStyle}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const ButtonStyle = {
  backgroundColor: "#f0f0f0",
  textAlign:"center",
  color:"#000000",
  borderStyle:"none",
  display:"flex",
  alignItems:"end"
};

const ThankyouWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 38px;
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #292929;
    text-align: center;
    margin: 20px 0 0 0;
    span {
      font-weight: 700;
    }
  }
`;
