import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import ThankyouModal from "../Components/ThankyouModal";
import Mobileheader from "../Components/Mobileheader";
import { Container, HeaderContainer } from "../GobalStyle";
import { CashPromoting, FormLowerImage } from "../Utils/Images";

const Advertisingform = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const initialValues = {
    ambassadorName: "",
    companyName: "",
    email: "",
    instagram: "",
    tikTok: "",
    youTube: "",
    description: "",
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    ambassadorName: Yup.string().required("Ambassador name is required"),
    companyName: Yup.string().required("Company name is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values, "valuessssss");
    let baseurl = "https://app.ibeor.com/admin/sendAmbassadorEmail";
    // let baseurl = "https://app.ibeor.com/api/sendParternerUsEmail";
    try {
      axios
        .post(baseurl, {
          ambassdorName: values?.ambassadorName,
          companyName: values?.companyName,
          email: values?.email,
          description: values?.description,
          instagram: values?.instagram,
          tikTok: values?.tikTok,
          youTube: values?.youTube,
        })
        .then((res) => {
          console.log(res, "rsssssssssssss");
          if (res?.data?.status === 200) {
            setLoading(false);
            setOpenModal(true);
          } else {
            setLoading(false);
            toast.error(res.response.data.message, { theme: "colored" });
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            error?.response?.data?.message ||
              error?.message ||
              "something went wrong",
            { theme: "colored" }
          );
        });
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong",
        { theme: "colored" }
      );
    }
  };

  const CustomInputComponent = (props) => <textarea {...props} />;

  const handleClick = () => {
    setOpenModal(false);
    window.location.reload();
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    console.log(window, "wwwwwww");

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AdvertisingFormWrapper>
      {openModal && (
        <ThankyouModal
          show={openModal}
          onHide={() => setOpenModal(false)}
          handleClick={handleClick}
        />
      )}
      <HeaderContainer>
        {windowWidth > 750 ? <Header /> : <Mobileheader />}
      </HeaderContainer>

      <div className="brand-ambassador">
        <h1>
          {/* <b>Become iBeor Brand Ambassador and Make Money</b> */}
          <b>Become an Affiliate</b>
        </h1>
        {/* <button className="sign-up">Sign up</button> */}
        <div>{/* <SignUpButton type="button">Sign up</SignUpButton> */}</div>
      </div>

      <div className="Cash-promotingWrapper">
        <div className="Cash-promoting">
          <div className="Cash-promoting-content">
            {/* <h1>Earn Cash Promoting iBeor Dating App</h1>
            <p>
              Earn Cash Promoting iBeor Dating App Interested in Joining iBeor?
              This is a great opportunity to turn your Life around by making
              passive income. Join Now!!!
            </p> */}
            <p>
              Welcome to iBeor System affiliate program, an excellent way to
              generate more revenue with your website or social media platforms.
            </p>
          </div>
          <div className="Cash-promoting-image">
            <div className="Cash-Left">
              <img className="Cash-Left-img" alt="" src={CashPromoting} />
            </div>
            {/* <div className="Cash-Right">
              <img
                className="Cash-Right-img"
                alt=""
                src="https://static.wixstatic.com/media/5f7a31_3080f5e92f3c43fa933b8bf7b84fe499~mv2.gif"
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className="card-content">
        <div className="gray-div" />
        <div className="white" />
        <div className="wrapper">
          <div className="card">
            <div>
              <div className="logo">
                <img
                  src="https://static.wixstatic.com/media/5f7a31_b8b3d5c96a1f4eceb18e953973ebb39a~mv2.png/v1/fill/w_115,h_115,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tap.png"
                  alt="logo"
                />
              </div>
              <div className="number">
                <img
                  src={
                    "https://static.wixstatic.com/media/5f7a31_2026af0b6e6140cf98ae351b9a64952e~mv2.png/v1/fill/w_24,h_24,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/circle-1.png"
                  }
                  alt="number"
                />
              </div>
              <h3>Sign up</h3>
              <p>
                Join tens of thousands of influencers, publishers and bloggers
                who are earning with the iBeor Affiliate Program.
              </p>
            </div>

            <div>
              <div className="logo">
                <img
                  src={
                    "https://static.wixstatic.com/media/5f7a31_4fc34b0af333445fb2f4101e37c12a7a~mv2.png/v1/fill/w_105,h_105,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5f7a31_4fc34b0af333445fb2f4101e37c12a7a~mv2.png"
                  }
                  alt="logo"
                />
              </div>
              <div className="number">
                <img
                  src={
                    "https://static.wixstatic.com/media/5f7a31_bc8a94c16c004ca1a3af6060f3683ec4~mv2.png/v1/fill/w_29,h_29,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5f7a31_bc8a94c16c004ca1a3af6060f3683ec4~mv2.png"
                  }
                  alt="number"
                />
              </div>
              <h3>Share your affiliate link</h3>
              <p>
                Share your Affiliate link with your audience. You will have your
                own customized links for publishers, individual bloggers and
                social media influencers.
              </p>
            </div>

            <div>
              <div className="logo">
                <img
                  src={
                    "https://static.wixstatic.com/media/5f7a31_28819a71cf464ecaaed75dd13cf60535~mv2.png/v1/fill/w_129,h_129,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5f7a31_28819a71cf464ecaaed75dd13cf60535~mv2.png"
                  }
                  alt="logo"
                />
              </div>
              <div className="number">
                <img
                  src={
                    "https://static.wixstatic.com/media/5f7a31_dc60b56035034acdaee53913f132a01c~mv2.png/v1/fill/w_24,h_24,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5f7a31_dc60b56035034acdaee53913f132a01c~mv2.png"
                  }
                  alt="number"
                />
              </div>
              <h3>Earn</h3>
              <p>
                You will earn <span className="redWord">$1 per download -</span>{" "}
                every time your audience download and sign-up on iBeor. Earn $
                promoting iBeor with qualifying download.
              </p>
            </div>
          </div>
          <div className="white-div">
            <div className="style">
              <div className="earn-cash">
                <p>Recommend iBeor. Earn Cash.</p>
                <SignUpButton
                  shadow={"1.41px 1.41px 8px #000000"}
                  background={"#FFF86B"}
                  radius={"7px"}
                  color="black"
                >
                  sign up
                </SignUpButton>
              </div>
            </div>
            <div className="applogo">
              <img
                src="https://static.wixstatic.com/media/5f7a31_9fcd923258fc4106975ba47fa9a51fe0~mv2.png/v1/fill/w_194,h_129,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5f7a31_9fcd923258fc4106975ba47fa9a51fe0~mv2.png"
                alt="app-logo"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid-content-wrapper">
        <div className="grid-content">
          <div className="grid-content-yellow">
            {yellowDivContent?.map((val) => (
              <>
                <div style={{ textAlign: "center" }}>
                  <strong>{val?.title}</strong>
                </div>
                <p>{val?.para}</p>
                <div>
                  <strong>{val?.heading}</strong>
                </div>
                <ul>
                  {val?.list?.map((li) => (
                    <li>{li}</li>
                  ))}
                </ul>
              </>
            ))}
          </div>
          <div className="grid-content-yellow grid-content-light">
            {pinkDivContent?.map((val) => (
              <>
                <div>
                  <strong>{val?.title}</strong>
                </div>
                <p>{val?.para}</p>
                <div>
                  <strong>{val?.heading}</strong>
                </div>
                <ol>
                  {val?.list?.map((li) => (
                    <li>{li}</li>
                  ))}
                </ol>
              </>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="grid-content">
        <div className="grid-content-yellow">
          <h3>
            <b>Are you a Social Media Influencer</b>
          </h3>
          <div className="Question-ans">
            <h3>What is a Brand Ambassador?</h3>
            <p>
              A brand ambassador, also known as a corporate ambassador or
              influencer, is a professional who increases awareness of a brand
              by publicly representing the company and its products or services.
              Brand ambassadors act as spokespeople, attempting to influence a
              community to buy a company’s product or service.{" "}
            </p>
          </div>
          <div className="Question-ans">
            <h3>Are you a Brand Ambassador?</h3>
            <p>
              Do you have voice and followers on Social Medias then this program
              is for you. Make money with iBeor by promoting our Brand on your
              platforms. You should have either one of these social medias
              platforms. YouTube, Instagram, Facebook, Twitter, TikTok, and
              Passionate about iBeor mission to spread this dating app.{" "}
            </p>
          </div>
          <div className="Question-ans">
            <h3>The role of the Ambassador</h3>
            <span>
              <li></li>{" "}
              <p>
                Create social media posts (videos & images) that motivate
                friends, family & followers to Download the iBeor Dating App.{" "}
              </p>
            </span>
            <span>
              <li></li>{" "}
              <p>
                Represent and spread iBeor brand on your social media platforms.{" "}
              </p>
            </span>
            <span>
              <li></li>{" "}
              <p>
                Engage with your friends, followers and fans and tell them about
                iBeor by following us also on all social media Platforms like
                Instagram, TikTok, Facebook Page, YouTube, Twitter etc.{" "}
              </p>
            </span>
          </div>
        </div>
        <div className="grid-content-light">
          <h3>
            <b>How does the program work and how do I get paid?</b>
          </h3>

          <div className="Question-ans">
            <h3>HOW DO BRAND AMBASSADORS GET PAID?</h3>

            <ol>
              <li>
                Fill out the form below to express your interest in the program.
                You must have a public Social Media page to be considered with
                lots of views and comments.
              </li>
              <li>
                If accepted into the program, you will receive a unique link to
                track your engagement. The link will be active for a Month and
                every time action is taken you get paid.
              </li>
              <li>
                Ambassadors must create two social media posts to advertise the
                app and tell people to download iBeor App using the unique link.
                At least one of the posts must be a video. Each post must remain
                on your page for at least two weeks.{" "}
              </li>
              <li>
                After a month, we will pay you base on the quality of the
                traffic plus a rate for every click to your unique link.
                (Payment details and rates will be provided via email) PayPal or
                Cashapp payment.{" "}
              </li>
            </ol>

            <div className="Light-Content-Image">
              <img
                className="Light-Content-img"
                alt=""
                src="https://static.wixstatic.com/media/5f7a31_b5420da028b14db4a6df11f23a8d141e~mv2.gif"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="formWrapper">
        <h2>
          Complete the form below and we will get back to you within 24 Hours.
          Brand Ambassador Contract Form
        </h2>

        <div className="form">
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <section>
                  <div>
                    <label className="label">
                      <i>Ambassador Name *</i>
                    </label>
                    <br />
                    <Field
                      className="input"
                      type="text"
                      name="ambassadorName"
                    />
                    {errors.ambassadorName && touched.ambassadorName ? (
                      <div style={{ color: "red", padding: "5px 0 0 0" }}>
                        {errors.ambassadorName}
                      </div>
                    ) : null}
                  </div>
                  <br />
                  <br />

                  <div>
                    <label className="label">
                      <i>Company Name *</i>
                    </label>
                    <br />

                    <Field className="input" type="text" name="companyName" />
                    {errors.companyName && touched.companyName ? (
                      <div style={{ color: "red", padding: "5px 0 0 0" }}>
                        {errors.companyName}
                      </div>
                    ) : null}
                  </div>
                  <br />
                  <br />

                  <div>
                    <label className="label">
                      <i>Email *</i>
                    </label>
                    <br />

                    <Field className="input" type="text" name="email" />
                    {errors.email && touched.email ? (
                      <div style={{ color: "red", padding: "5px 0 0 0" }}>
                        {errors.email}
                      </div>
                    ) : null}
                  </div>

                  <br />

                  <h2>Social Media Platforms</h2>
                  <div className="Social-Media-Inputs">
                    <span>
                      <div>
                        <label className="label">Instagram</label>
                        <br />

                        <Field className="input" type="text" name="instagram" />
                        {errors.instagram && touched.instagram ? (
                          <div style={{ color: "red", padding: "5px 0 0 0" }}>
                            {errors.instagram}
                          </div>
                        ) : null}
                      </div>
                    </span>
                    <span>
                      <div>
                        <label className="label">TikTok</label>
                        <br />

                        <Field className="input" type="text" name="tikTok" />
                        {errors.tikTok && touched.tikTok ? (
                          <div style={{ color: "red", padding: "5px 0 0 0" }}>
                            {errors.tikTok}
                          </div>
                        ) : null}
                      </div>
                    </span>
                    <span>
                      <div>
                        <label className="label">YouTube</label>
                        <br />

                        <Field className="input" type="text" name="youTube" />
                        {errors.youTube && touched.youTube ? (
                          <div style={{ color: "red", padding: "5px 0 0 0" }}>
                            {errors.youTube}
                          </div>
                        ) : null}
                      </div>
                    </span>
                  </div>
                  <div className="textareaWrapper">
                    <div style={{ margin: "20px 0" }}>
                      <label className="label">
                        <i>
                          Tell us more about you and why you fit as a best
                          candidate to Promote iBeor Dating App
                        </i>
                      </label>
                      <Field
                        className="input"
                        name="description"
                        as={CustomInputComponent}
                      />
                      {errors.description && touched.description ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="submitBtnWrapper">
                    {loading ? (
                      <p className="loading">Loading...</p>
                    ) : (
                      <button type="submit">Submit</button>
                    )}
                  </div>
                </section>
              </Form>
            )}
          </Formik>
        </div>
      </div> */}

      <div className="formLowerImageWrapper">
        <img style={{ width: "100%" }} alt="" src={FormLowerImage} />
      </div>

      <Footer />
    </AdvertisingFormWrapper>
  );
};
export default Advertisingform;

const yellowDivContent = [
  { title: `Are you a Brand Ambassador?` },
  {
    para: `Do you have voice and followers on Social Medias then this program is for you. Make money with iBeor by promoting our Brand on your platforms. You should have either one of these social medias platforms. YouTube, Instagram, Facebook, Twitter, TikTok, and Passionate about iBeor mission to spread this dating app.`,
  },
  { heading: `The role of the Ambassador` },
  {
    list: [
      `Create social media posts (videos & images) that motivate friends, family & followers to Download the iBeor Dating App. `,
      `Represent and spread iBeor brand on your social media platforms.`,
      `Engage with your friends, followers and fans and tell them about iBeor by following us also on all social media Platforms like Instagram, TikTok, Facebook Page, YouTube, Twitter etc.`,
    ],
  },
];

const pinkDivContent = [
  { title: `How does the program work and how do I get paid?` },
  { heading: `HOW DO BRAND AMBASSADORS GET PAID?` },
  {
    list: [
      `Fill out the form below to express your interest in the program. You must have a public Social Media page to be considered with lots of views and comments.`,

      `If accepted into the program, you will receive a unique link to track your engagement. The link will be active for a Month and every time action is taken you get paid.`,

      `Ambassadors must create two social media posts to advertise the app and tell people to download iBeor App using the unique link. At least one of the posts must be a video. Each post must remain on your page for at least two weeks.`,

      `Every week, two weeks or month you can request payout. We will pay you base on the quality of the traffic plus a rate for every click to your unique link. (Payment details and rates will be provided via email) PayPal or Cashapp payment.`,
    ],
  },
];

export const SignUpButton = styled.button`
  background-color: ${(props) => props?.background || "#5182ff"};
  border: 0px solid #5182ff;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: ${(props) => props.color || "#ffffff"};
  font-size: 19px;
  padding: 4px 30px;
  border-radius: ${(props) => props?.radius || "20px"};
  height: fit-content;
  cursor: pointer;
  box-shadow: ${(props) => props?.shadow || "none"};
`;

const AdvertisingFormWrapper = styled.div`
  width: 100%;
  height: 100%;

  .brand-ambassador {
    /* background-color: #fff205; */
    background-color: white;
    width: 65%;
    margin: auto;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;

    @media (max-width: 1500px) {
      width: 70%;
    }
    @media (max-width: 800px) {
      width: 95%;
    }

    h1 {
      text-align: center;
    }
    @media (max-width: 480px) {
      gap: 10px;
      div {
        width: 100%;
        display: flex;
        justify-content: end;
      }
      h1 {
        text-align: center;
        font-size: 20px;
      }
    }
  }

  .card-content {
    background-color: #f7f7f7;
    padding-bottom: 30px;

    .wrapper {
      width: 65%;
      margin: auto;
      @media (max-width: 1500px) {
        width: 70%;
      }
      @media (max-width: 800px) {
        width: 95%;
      }
    }
    .card {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      border: 0px solid;
      padding: 20px 0 0 0;
      background-color: #f7f7f7;
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 600px) {
        grid-template-columns: auto;
      }
    }
    .redWord {
      color: #ff4040;
      font-size: 15px;
      font-weight: bold;
    }
    .gray-div {
      background-color: #d1d1d1;
      height: 18px;
    }
    .white {
      background-color: white;
      height: 10px;
    }
    div {
      text-align: center;
      .logo {
        width: 130px;
        height: 130px;
        margin: auto;
      }
      .number {
        margin: auto;
        width: 29px;
        height: 29px;
      }
      h3 {
        margin: 5px 0 15px 0;
      }
    }

    .white-div {
      position: relative;
      background-color: white;
      height: 230px;
      overflow: hidden;
    }
    .style {
      background: #f7f7f7;
      overflow: hidden;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      height: 500px;
      border: 1px solid white;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .earn-cash {
      background-color: #c0f3c8;
      text-align: center;
      padding: 10px 0 50px 0;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 115px;
      p {
        letter-spacing: 0.15em;
        color: #9d6200;
        font-size: 22px;
        line-height: 1.7em;
        @media (max-width: 600px) {
          font-size: 16px;
        }
        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }
    .applogo {
      position: absolute;
      bottom: 0;
      width: 180px;
      height: 120px;

      @media (max-width: 1300px) {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(-50%);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .Cash-promotingWrapper {
    width: 100%;
    /* background-color: #282626; */
    /* color: #e4bfad; */
    background-color: black;
    color: white;

    .Cash-promoting {
      width: 65%;
      margin: auto;

      @media (max-width: 800px) {
        width: 95%;
      }
      @media (max-width: 1500px) {
        width: 70%;
      }

      .Cash-promoting-content {
        width: 100%;

        p {
          /* width: 50%; */
          width: 100%;
          padding-top: 14px;
          margin-bottom: 0rem;
          font-size: 20px;
          line-height: 1.5em;
        }
        @media (max-width: 800px) {
          p {
            width: 90%;
          }
        }
      }
      .Cash-promoting-image {
        width: 100%;
        display: flex;

        .Cash-Left {
          width: 70%;
          .Cash-Left-img {
            /* width: 90%; */
            width: 100%;
          }
          @media (max-width: 880px) {
            width: 100%;
          }
          @media (max-width: 1994px) {
            width: 100%;
            .Cash-Left-img {
              height: 100%;
            }
          }
        }
        .Cash-Right {
          width: 30%;
          .Cash-Right-img {
            margin-top: 5rem;
            @media (max-width: 2300px) {
              margin-top: 8rem;
            }
            @media (max-width: 2360px) {
              margin-top: 12.5rem;
            }
            @media (max-width: 2380px) {
              margin-top: 13rem;
            }
            @media (max-width: 2250px) {
              margin-top: 10rem;
            }
            @media (max-width: 2050px) {
              margin-top: 7rem;
            }
            @media (max-width: 2090px) {
              margin-top: 7rem;
            }
            @media (max-width: 2190px) {
              margin-top: 8.4rem;
            }
          }
          @media (max-width: 880px) {
            width: 0%;
            display: none;
          }
        }
      }
    }
  }

  .grid-content-wrapper {
    width: 65%;
    margin: auto;
    @media (max-width: 1500px) {
      width: 70%;
    }
    @media (max-width: 800px) {
      width: 95%;
    }
  }

  .grid-content {
    display: flex;
    .grid-content-yellow {
      width: 50%;
      padding: 10px 10px 40px;
      background-color: #feeb05;
      font-size: 16px;
      p,
      li {
        line-height: 1.7;
      }
      @media (max-width: 1400px) {
        padding: 10px 10px 40px;
      }
    }
    .grid-content-light {
      background-color: #e4bfad;
    }

    /* .grid-content-yellow {
      width: 50%;
      padding: 10px 8rem;
      background-color: #feeb05;

      @media (max-width: 1400px) {
        padding: 10px 4rem;
      }

      .Question-ans {
        margin-top: 2rem;
        h3 {
          text-align: left;
        }
        span {
          display: flex;
        }
      }
    } */
    /* .grid-content-light {
      width: 50%;
      padding: 10px 8rem;
      background-color: #e4bfad;

      @media (max-width: 1400px) {
        padding: 10px 4rem;
      }
      .Question-ans {
        margin-top: 2rem;
        h3 {
          text-align: left;
        }
        span {
          display: flex;
        }
      }
      .Light-Content-Image {
        width: 100%;
        display: flex;
        justify-content: center;
        .Light-Content-img {
          height: 25rem;
          margin-bottom: -10px;

          @media (max-width: 800px) {
            margin-bottom: 0;
          }
        }
      }
    } */
    @media (max-width: 800px) {
      display: block;

      .grid-content-yellow {
        width: 100%;
        padding: 10px 20px;
      }

      .grid-content-light {
        width: 100%;
        /* padding: 0; */
      }
    }
  }
  .formWrapper {
    width: 100%;
    background-color: #fafafa;
    margin-bottom: 2rem;
    h2 {
      text-align: center;
      padding: 10px 18rem;
    }

    @media (max-width: 800px) {
      h2 {
        padding: 0;
        font-size: 20px;
      }
    }
    .form {
      margin: auto;
      border: 1px solid #cecece;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      section {
        padding: 4rem 10rem;

        .formLowerImageWrapper {
          width: 100%;
        }

        .label {
          color: #1a2df3;
          font-family: georgia;
          font-weight: 700;
        }

        .input {
          padding: 10px;
          width: 100%;
          border: 1px solid red;
          background-color: #ffecf1;
        }
        h2 {
          text-align: center;
          padding: 0;
          margin-top: 10px;
          font-family: sans-serif;
          font-weight: 700;
        }
        .Social-Media-Inputs {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 20px;

          span {
            text-align: center;
          }
        }
      }
      @media (max-width: 800px) {
        width: 100%;
        section {
          padding: 1rem;
        }
      }
    }
    .textareaWrapper {
      width: 100%;
      margin-top: 3.5rem;

      textarea {
        margin-top: 10px;
        width: 100%;
        height: 140px;
        border-bottom: 1px solid #1a2df3;
        padding: 10px 20px;
        background-color: #ffecf1;
      }
      textarea:focus {
        border-bottom: 5px solid #1a2df3;
      }
    }
    .submitBtnWrapper {
      margin-top: 1rem;
      button {
        background-color: #1a2df3;
        padding: 10px;
        font-size: 20px;
        color: #fff;
        width: 100%;
      }
      .loading {
        background-color: #1a2df3;
        padding: 10px;
        font-size: 20px;
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
