import { Field, Form, Formik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import styledComponents from "styled-components";
import { Container, HeaderContainer, SingleLine } from "../GobalStyle";
import * as Yup from "yup";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import ThankyouModal from "../Components/ThankyouModal";

export default function Partnership() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    Name: "",
    BusinessName: "",
    Email: "",
    PhoneNumber: "",
    Website: "",
    Description: "",
  };

  const SignupSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    Email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    BusinessName: Yup.string().optional("Business Name is required"),
    PhoneNumber: Yup.string()
      .matches("^[0-9]*$", "Invaild phone number")
      .min(10, "Invaild phone number, It should be min 10 digit")
      .max(10, "Invaild phone number, It should only have max 10 digit")
      .required("Phone Number is required"),
    Website: Yup.string().optional("Website is required"),
    Description: Yup.string().required("Description is required"),
  });

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    let baseurl = "https://app.ibeor.com/admin/sendParternerUsEmail";
    // let baseurl = "https://app.ibeor.com/api/sendParternerUsEmail";
    try {
      axios
        .post(baseurl, {
          name: values?.Name,
          businessname: values?.BusinessName,
          email: values?.Email,
          phoneNumber: values?.PhoneNumber,
          website: values?.Website,
          description: values?.Description,
        })
        .then((res) => {
          console.log(res, "rsssssssssssss");
          if (res?.data?.status === 200) {
            setLoading(false);
            setOpenModal(true);
          } else {
            setLoading(false);
            toast.error(res.response.data.message, { theme: "colored" });
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            error?.response?.data?.message ||
              error?.message ||
              "something went wrong",
            { theme: "colored" }
          );
        });
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong",
        { theme: "colored" }
      );
    }
  };

  const CustomInputComponent = (props) => <textarea {...props} />;

  const handleClick = () => {
    setOpenModal(false);
    window.location.reload();
  };

  return (
    <div>
      <>
        {openModal && (
          <ThankyouModal
            show={openModal}
            onHide={() => setOpenModal(false)}
            handleClick={handleClick}
          />
        )}

        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <NewPartnerShipContact>
          <div>
            <h1>iBeor Partnership Program</h1>
            <p>
              Complete the form below and we will get back to you within 24
              Hours.
              <br />
              Tell us about your business Plans and why you want to join us.
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched }) => (
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="PartnershipForm">
                    <section>
                      <label>Your Name *</label>
                      <Field name="Name" />
                      {errors.Name && touched.Name ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.Name}
                        </div>
                      ) : null}
                    </section>

                    <section>
                      <label>Business Name (optional)</label>
                      <Field name="BusinessName" />
                      {errors.BusinessName && touched.BusinessName ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.BusinessName}
                        </div>
                      ) : null}
                    </section>

                    <section>
                      <label>Email *</label>
                      <Field name="Email" />
                      {errors.Email && touched.Email ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.Email}
                        </div>
                      ) : null}
                    </section>

                    <section>
                      <label>Phone Number *</label>
                      <Field name="PhoneNumber" />
                      {errors.PhoneNumber && touched.PhoneNumber ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.PhoneNumber}
                        </div>
                      ) : null}
                    </section>

                    <section>
                      <label>Website (optional)</label>
                      <Field name="Website" />
                      {errors.Website && touched.Website ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.Website}
                        </div>
                      ) : null}
                    </section>

                    <section>
                      <label>Tell us why you want to partner with us.</label>
                      <Field name="Description" as={CustomInputComponent} />
                      {errors.Description && touched.Description ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.Description}
                        </div>
                      ) : null}
                    </section>

                    {loading ? (
                      <p className="loading">Loading...</p>
                    ) : (
                      <button type="submit">Submit</button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </NewPartnerShipContact>
      </>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const NewPartnerShipContact = styledComponents.div`

width:100%;
height:100%;
padding: 0 0 150px 0;
background:#feeb05;
div {
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    padding: 0;
    margin-top: 10px;
    font-family: "Nunito Sans",sans-serif;
    font-weight: 700;
  }
  p {
    text-align: center;
    padding: 0;
    font-weight: 500;
    margin-top: 10px;
    font-family: "Nunito Sans",sans-serif;
    font-size:18px;
  }



  .PartnershipForm {
    width:80%;
    height:100%;
    background:#fff;
    display:flex;
    flex-direction:column;
    padding:40px 0;

    section{
      width:70%;
      display:flex;
      margin:20px 0;
      flex-direction:column;
      label {
        color:#41758e;
        font-family: "Nunito Sans",sans-serif;
      }

      input{
        width:100%;
        border-style:none;
        border-bottom:1px solid #41758e !important;
        padding-left: 5px;
        font-family: "Nunito Sans",sans-serif;
      }


      textarea {
        width:100%;
        height:300px;
        border:1px solid #41758e;
        padding-left: 10px;
        font-family: "Nunito Sans",sans-serif;
      }
    }

    .loading {
      width:70%;
      height:51px;
      background:#b9584b;
      color:#fff;
      font-weight: 700;
      margin-top: 10px;
      font-family: "Nunito Sans",sans-serif;
      font-size: 22px;
      display:flex;
      align-items:center;
      justify-content:center;
    }

    button {
      width:70%;
      height:51px;
      background:#b9584b;
      color:#fff;
      font-weight: 700;
      margin-top: 10px;
      font-family: "Nunito Sans",sans-serif;
      font-size: 22px;
    }
  }
}`;
