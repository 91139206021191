import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { HeaderLogoWrap } from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import ThankyouModal from "../Components/ThankyouModal";
import Header from "../Components/Header";
import Mobileheader from "../Components/Mobileheader";

export default function ContactUs() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    topic: "",
    description: "",
    email: "",
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    topic: Yup.string().required("Topic is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values, "valuessssss");
    let baseurl = "https://app.ibeor.com/admin/sendContactUsEmail";
    // let baseurl = "https://app.ibeor.com/api/sendParternerUsEmail";
    try {
      axios
        .post(baseurl, {
          topic: values?.topic,
          email: values?.email,
          description: values?.description,
        })
        .then((res) => {
          console.log(res, "rsssssssssssss");
          if (res?.data?.status === 200) {
            setLoading(false);
            setOpenModal(true);
          } else {
            setLoading(false);
            toast.error(res.response.data.message, { theme: "colored" });
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            error?.response?.data?.message ||
              error?.message ||
              "something went wrong",
            { theme: "colored" }
          );
        });
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong",
        { theme: "colored" }
      );
    }
  };

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const CustomInputComponent = (props) => <textarea {...props} />;

  const handleClick = () => {
    setOpenModal(false);
    window.location.reload();
  };

  return (
    <div>
      {openModal && (
        <ThankyouModal
          show={openModal}
          onHide={() => setOpenModal(false)}
          handleClick={handleClick}
        />
      )}
      <ContactWrapper>
        {/* <ContactContainer> */}
        {/* <HeaderLogoWrap>
            <img
              // className="ContactUsLogo"
              src={newibeorlogo}
              alt="LOGO"
              onClick={() => navigate("/")}
            />
          </HeaderLogoWrap> */}
        {size > 750 ? <Header /> : <Mobileheader />}
        {/* </ContactContainer> */}
        <GreenDiv>
          <ContactContainer>
            <h1>Contact Us</h1>

            <h2>
              Got something you want to talk about? Contact us or email us and
              we promise to get back to you as soon as we can.
            </h2>
          </ContactContainer>
        </GreenDiv>

        <ContactContainer>
          <HelpBox>
            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched }) => (
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ContactContainer>
                    <h1>How can we help?</h1>

                    <div>
                      <label>Choose a Topic *</label>

                      <Field name="topic" as="select">
                        <option selected disabled hidden></option>

                        <option>Success Stories</option>
                        <option>Help / Support</option>
                        <option>Question about something else</option>
                      </Field>
                      {errors.topic && touched.topic ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.topic}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <label>Email *</label>
                      <Field name="email" />
                      {errors.email && touched.email ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.email}
                        </div>
                      ) : null}
                    </div>

                    <div style={{ margin: "20px 0" }}>
                      <label>Tell us what you need help with</label>
                      <Field name="description" as={CustomInputComponent} />
                      {errors.description && touched.description ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.description}
                        </div>
                      ) : null}
                    </div>

                    <div style={{ margin: "20px 0" }}>
                      {loading ? (
                        <p className="loading">Loading...</p>
                      ) : (
                        <button type="submit">Send</button>
                      )}
                    </div>
                  </ContactContainer>
                </Form>
              )}
            </Formik>
          </HelpBox>
        </ContactContainer>
      </ContactWrapper>

      {size >= 500 && <Footer />}
    </div>
  );
}

const HelpBox = styled.div`
  width: 100%;
  height: 100%;
  background: #f6ebe4;
  padding: 0 0 60px 0;
  margin: 0 0 73px 0;

  h1 {
    color: #624c40;
    text-align: center;
    font-size: 25px;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 50px 0;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      font-family: "Nunito Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
    }

    select {
      width: 100%;
      height: 62px;
      border-radius: 12px;
      padding: 0 12px;
      box-shadow: 7px 7px #e4bfad;
      font-size: 18px;
      font-family: "Nunito Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
    }

    select:hover {
      border: 2px solid #624c40;
    }

    input {
      width: 100%;
      height: 62px;
      border-radius: 12px;
      padding: 0 12px;
      box-shadow: 7px 7px #e4bfad;
      font-size: 18px;
      font-family: "Nunito Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
    }
    input:hover {
      border: 2px solid #624c40;
    }

    textarea {
      width: 100%;
      height: 271px;
      border-radius: 12px;
      padding: 20px;
      box-shadow: 7px 7px #e4bfad;
      font-size: 18px;
      font-family: "Nunito Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
    }

    @media (max-width: 500px) {
      h1 {
        padding: 30px 0 20px 0;
      }
      select {
        height: 52px;
        font-size: 14px;
      }
      textarea {
        height: 180px;
        font-size: 14px;
      }
    }

    textarea:hover {
      border: 2px solid #624c40;
    }

    button {
      width: 108px;
      height: 52px;
      border-radius: 12px;
      background: #b77a5c;
      color: #583700;
      font-size: 14px;
      font-weight: 500;
      font-family: "Nunito Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
      margin: 0 auto;
    }
    button:hover {
      opacity: 0.9;
    }

    .loading {
      width: 108px;
      height: 52px;
      border-radius: 12px;
      background: #b77a5c;
      color: #583700;
      font-size: 14px;
      font-weight: 500;
      font-family: "Nunito Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const GreenDiv = styled.div`
  width: 100%;
  height: 100%;
  background: #3f7652;
  ${"" /* margin: 20px 0 0 0; */}
  padding: 30px 0;
  color: #ffffff;

  h1 {
    font-size: 55px;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 20px 0;
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 20px 0;
  }

  h3 {
    font-size: 30px;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 5px 0;
  }
  h4 {
    font-size: 22px;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    padding: 0;
    margin: 0;
  }

  h5 {
    font-size: 22px;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 45px;
      padding: 20px 0;
    }

    h2 {
      font-size: 25px;
      font-weight: 500;
      padding: 20px 0;
    }

    h3 {
      font-size: 25px;
      font-weight: 400;
      padding: 5px 0;
    }
    h4 {
      font-size: 16px;
      font-weight: 400;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const ContactContainer = styled.div`
  width: 72%;
  height: 100%;
  margin: auto;

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 420px) {
    width: 94%;
  }
`;
const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;
