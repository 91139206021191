import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import { FooterContainer, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { newibeorlogo } from "../Utils/Images";

export default function Security() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={newibeorlogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>
        <SecurityWrapper>
          <h2>SECURITY</h2>

          <p>
            IBEOR considers the security of our applications and the privacy of
            our users’ data extremely important. While it is impossible to
            predict the full range of future cyber threats against our systems,
            we regularly assess and work to improve our security in an effort to
            enhance the safety of our user community. ​
          </p>

          <h2>Security Practices ​</h2>
          <p>
            IBEOR employs physical, procedural and electronic controls in
            connection with the protection of our systems and user data from
            unauthorized access. This includes regularly having our systems and
            applications reviewed for potential issues by our internal security
            team and outside security firms.
          </p>
          <h2>Reporting Security Vulnerabilities</h2>
          <p>
            IBEOR welcomes input from the security research community to advance
            the cause of improving the security of our applications and user
            data. To that end, we encourage security researchers to responsibly
            disclose any potential vulnerabilities uncovered to
            security@ibeor-app.com. Reports received through this channel will
            receive a prompt reply, and if you do not receive such a response,
            we ask that you please attempt to contact us again. To protect our
            users, we also request that you please refrain from sharing
            information about any potential vulnerabilities with anyone outside
            of IBEOR, until we have confirmed with you that any such
            vulnerability has been properly mitigated.
          </p>
          <p>
            IBEOR’s bug bounty program is private and inclusion is by invite
            only. Researchers who follow generally accepted responsible
            disclosure practices and submit quality reports to our Security team
            will be evaluated for inclusion at our discretion. We explicitly
            prohibit testing Denial of Service (DoS) or use of automated
            scanning tools against any of our applications or infrastructure
          </p>
        </SecurityWrapper>
      </FooterContainer>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const SecurityWrapper = styledComponents.div`

h3 {
  text-align: left;
  font-family: "Nunito Sans",sans-serif;
      list-style: none;
      line-height:34px;
      font-size: 26px;
      font-weight: 500;
      margin: 0 0 20px 0;

}

p{
  font-family: "Nunito Sans",sans-serif;
  list-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin: 20px 0 ;
  color:#505965;
}
h2 {
  text-align: left;
  font-family: "Nunito Sans",sans-serif;
  list-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 20px 0 ;
  color:#000;

}

`;
